import {FILTER_OPERATIONS, USER_EVENT_TYPE} from '../interfaces/global-interfaces';
import {getAllSubTablesWithCache, updateFieldsLayout, updateFiltersLayout} from './actionUtils';
import {actionTypes} from './actionTypes';
import {updateCurrentForm} from './currentFormActions';
import {ELayoutType, IGroupOptions, ILayoutItem, ITableOptions} from '../SecondaryMethods/formItems/itemInterfaces';
import {AppDispatch, RootState} from '../../store';
import LayoutType from 'utilsOld/systemObjects/LayoutType';
import TableCacheDataSource from 'utilsOld/datasource/TableCacheDataSource';
import {currentFormActions} from './actions';
import {IKanbanDataColumn} from 'components/widgets/Kanban/types';
import {getEvent} from '../../middlewares/userScript/utils/getEvent';
import {makeSysFormSelectorByName} from '../requests/selectors';
import {AppointmentDataType} from '../../middlewares/userScript/events/usOnAppointmentElementClick';
import {GroupDataType} from '../../middlewares/userScript/events/usOnGroupButtonClick';
import {DataType} from '../../middlewares/userScript/events/usOnItemSwipe';
import {ItemDataType} from 'middlewares/userScript/events/usOnItemClick';

export const createEventPromise = <T extends any, E extends Error>() => {
  const event = {
    cancel: false,
    message: '',
    resolve: (_e: T) => undefined,
    reject: (_e: E) => undefined
  };

  const eventPromise = new Promise<T>((resolve, reject) => {
    // @ts-ignore
    event.resolve = resolve;
    // @ts-ignore
    event.reject = reject;
  });

  return {event, eventPromise};
};

export type EventPromise = ReturnType<typeof createEventPromise>['event'];

const hasEvent = (arg: Parameters<typeof getEvent>[0]) => !!getEvent(arg);

const checkEventActivity = ({
  formID,
  state,
  name,
  eventType
}: {
  formID: string;
  state: RootState;
  name?: string;
  eventType: USER_EVENT_TYPE;
}) => {
  const sysForm = makeSysFormSelectorByName()(state, formID)!;

  return !hasEvent({sysForm, name, eventType});
};

export const userScriptActions = {
  onInit:
    ({
      items,
      groups,
      formID,
      formKey
    }: {
      items: ILayoutItem<any>[];
      groups: ILayoutItem<IGroupOptions>[];
      formID: string;
      formKey: string;
    }) =>
    (dispatch: any, getState: () => RootState) => {
      const {event, eventPromise} = createEventPromise();

      const subFormTables = getAllSubTablesWithCache(items, getState().currentForm);

      const subFormItems: ILayoutItem<ITableOptions>[] = [...items, ...subFormTables].filter(
        item => LayoutType.isTable(item.itemType) && item.options.cacheDataSource instanceof TableCacheDataSource
      );

      subFormItems.forEach(tableItem => {
        const cacheDataSource = tableItem.options.cacheDataSource as TableCacheDataSource;
        dispatch(
          currentFormActions.setDataSource({
            dataSource: cacheDataSource.dataSource(),
            formID: tableItem.options.formID
          })
        );
      });

      dispatch({
        type: actionTypes.ON_INIT,
        payload: {items, groups, formID, formKey, event},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_INIT_FORM
        }
      });
      return eventPromise;
    },
  onShow:
    ({formID, formKey}: {formID: string; formKey: string}) =>
    (dispatch: any) => {
      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_SHOW,
        payload: {formID, formKey, event},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_SHOW_FORM
        }
      });

      return eventPromise;
    },
  onApplyFilter:
    ({formID, formKey}: {formID: string; formKey: string}) =>
    (dispatch: any, getState: () => RootState) => {
      if (checkEventActivity({formID, eventType: USER_EVENT_TYPE.ON_FILTER_APPLY, state: getState()}))
        return Promise.resolve();

      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_FILTER_APPLY,
        payload: {formID, event, formKey},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_FILTER_APPLY
        }
      });

      return eventPromise;
    },
  onSave:
    ({formID, formKey}: {formID: string; formKey: string}) =>
    (dispatch: any, getState: () => RootState) => {
      if (checkEventActivity({formID, eventType: USER_EVENT_TYPE.ON_SAVE_FORM, state: getState()}))
        return Promise.resolve();

      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_SAVE,
        payload: {formID, event, formKey},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_SAVE_FORM
        }
      });

      return eventPromise;
    },
  onApply:
    ({formID, formKey}: {formID: string; formKey: string}) =>
    (dispatch: any, getState: () => RootState) => {
      if (checkEventActivity({formID, eventType: USER_EVENT_TYPE.ON_APPLY, state: getState()}))
        return Promise.resolve();

      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_APPLY,
        payload: {formID, event, formKey},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_APPLY
        }
      });

      return eventPromise;
    },
  onSummaryCalc:
    ({formID, formKey}: {formID: string; formKey: string}) =>
    (dispatch: any, getState: () => RootState) => {
      if (
        checkEventActivity({
          formID,
          eventType: USER_EVENT_TYPE.ON_SUMMARY_CALC,
          state: getState()
        })
      )
        return Promise.resolve();

      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_SUMMARY_CALC,
        payload: {formID, event, formKey},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_SUMMARY_CALC
        }
      });

      return eventPromise;
    },
  onClose:
    ({formID, formKey}: {formID: string; formKey: string}) =>
    (dispatch: any, getState: () => RootState) => {
      if (
        checkEventActivity({
          formID,
          eventType: USER_EVENT_TYPE.ON_CLOSE_FORM,
          state: getState()
        })
      )
        return Promise.resolve();

      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_CLOSE,
        payload: {formID, event, formKey},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_CLOSE_FORM
        }
      });

      return eventPromise;
    },
  onValueChanged:
    ({
      formID,
      newValue,
      name,
      displayValue,
      formKey,
      relativeField,
      componentInitiator = ELayoutType.FORM_FIELD,
      withUSEvent = true
    }: {
      formID: string;
      formKey: string;
      newValue: any;
      name: string;
      componentInitiator?: ELayoutType;
      displayValue?: string;
      relativeField?: {
        fieldName: string;
        value: any;
      };
      withUSEvent?: boolean;
    }) =>
    (dispatch: any, getState: () => RootState) => {
      const state = getState();

      // @ts-ignore
      const {prevValue, updatingOptions} = updateFieldsLayout({
        state,
        formID,
        name,
        newValue,
        displayValue,
        relativeField
      });

      dispatch(
        updateCurrentForm({
          formID,
          options: updatingOptions
        })
      );

      if (!withUSEvent) return Promise.resolve();

      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_FIELD_VALUE_CHANGED,
        payload: {
          formID,
          newValue,
          name,
          displayValue,
          event,
          formKey,
          eventPromise,
          prevValue,
          relativeField,
          componentInitiator
        },
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_FIELD_VALUE_CHANGED
        }
      });

      return eventPromise;
    },
  onFltValChanged:
    ({
      formID,
      value,
      operation,
      hasIsBlank,
      name,
      filterName,
      formKey,
      sender,
      displayValue,
      relativeField
    }: {
      formID: string;
      formKey: string;
      name: string;
      filterName: string;
      sender?: string;
      displayValue?: string;
      relativeField?: {
        fieldName: string;
        value: any;
      };
      operation: FILTER_OPERATIONS;
      value: any;
      hasIsBlank: boolean;
    }) =>
    (dispatch: any, getState: () => RootState) => {
      const state = getState();

      const {prevFilter, updatingOptions, itemsKey} = updateFiltersLayout({
        state,
        formID,
        formKey,
        filterName,
        value,
        displayValue,
        hasIsBlank,
        operation,
        sender
      });

      dispatch(
        updateCurrentForm({
          formID: itemsKey,
          options: updatingOptions
        })
      );

      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_FILTER_VALUE_CHANGED,
        payload: {
          formID,
          name,
          value,
          operation,
          hasIsBlank,
          filterName,
          formKey,
          displayValue,
          event,
          relativeField,
          prevFilter
        },
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_FILTER_VALUE_CHANGED,
          sender
        }
      });

      return eventPromise;
    },
  onInitLookupField:
    ({
      formID,
      formKey,
      name,
      componentInitiator = ELayoutType.FORM_FIELD
    }: {
      formID: string;
      formKey: string;
      name: string;
      componentInitiator?: ELayoutType;
    }) =>
    (dispatch: any, getState: () => RootState) => {
      const {event, eventPromise} = createEventPromise<{dataSource?: []; filter?: Record<string, any>}, Error>();

      if (checkEventActivity({formID, name, eventType: USER_EVENT_TYPE.ON_INIT_LOOKUP_FIELD, state: getState()}))
        return Promise.resolve({});

      dispatch({
        type: actionTypes.ON_INIT_LOOKUP_FIELD,
        payload: {
          formID,
          name,
          event,
          formKey,
          componentInitiator
        },
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_INIT_LOOKUP_FIELD
        }
      });

      return eventPromise;
    },
  onInitLookupFilter:
    ({formID, formKey, name}: {formID: string; formKey: string; name: string}) =>
    (dispatch: any, getState: () => RootState) => {
      const {event, eventPromise} = createEventPromise();

      if (
        checkEventActivity({
          formID,
          eventType: USER_EVENT_TYPE.ON_INIT_LOOKUP_FILTER,
          state: getState(),
          name
        })
      )
        return Promise.resolve({});

      dispatch({
        type: actionTypes.ON_INIT_LOOKUP_FILTER,
        payload: {
          formID,
          name,
          formKey,
          event
        },
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_INIT_LOOKUP_FILTER
        }
      });

      return eventPromise;
    },
  /**
   * @param {Object} args
   * @param {string} args.formID
   * @param {string} args.formKey
   * @param {string} args.name - Имя кнопки
   * @param {Object} args.usArguments - Объект аргументов для юзер скриптов
   *   usArguments.apiFilters - сформированные апи фильтры для перехода goto
   * @param {string | undefined} [args.sender] - определяет если кнопка нажата из сабформы. Если сабформа, то значение - 'subForm'
   * @param {function(userData: any): Promise<any>} [args.inheritMethod] - стандартаная функция с тулбара таблицы. Возвращает промис
   *  который сведетельсвует об окончании исполнения юзер скрипта.
   */
  onButtonClick:
    ({
      formID,
      formKey,
      name,
      sender,
      componentInitiator = ELayoutType.BUTTON,
      inheritMethod,
      usArguments = {},
      clickEventName = ''
    }: {
      formID: string;
      formKey: string;
      name: string;
      sender?: string;
      componentInitiator?: ELayoutType;
      usArguments: any;
      inheritMethod: (userData: any) => Promise<any>;
      clickEventName?: string;
    }) =>
    (dispatch: any) => {
      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_BUTTON_CLICKED,
        payload: {formID, formKey, name, inheritMethod, usArguments, event, componentInitiator, clickEventName},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_BUTTON_CLICK,
          sender
        }
      });

      return eventPromise;
    },
  onSelectionChanged:
    ({formID, formKey}: {formID: string; formKey: string}) =>
    (dispatch: any) => {
      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_SELECTION_CHANGED,
        payload: {formID, formKey, event},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_SELECTION_CHANGED
        }
      });
      return eventPromise;
    },
  setFocusedCell:
    ({
      formID,
      rowIndex,
      columnIndex,
      fieldName,
      cellValue,
      data,
      key
    }: {
      formID: string;
      fieldName: string;
      key: any;
      cellValue: any;
      data: Record<string, any>;
      rowIndex: number;
      columnIndex: number;
    }) =>
    (dispatch: any) => {
      dispatch({
        type: actionTypes.SET_FOCUSED_CELL,
        payload: {formID, rowIndex, columnIndex, fieldName, cellValue, data, key}
      });
    },
  onFocusedCellChanged:
    ({formID, formKey}: {formID: string; formKey: string}) =>
    (dispatch: any) => {
      dispatch({
        type: actionTypes.ON_FOCUSED_CELL_CHANGED,
        payload: {formID, formKey},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_FOCUSED_CELL_CHANGED
        }
      });
    },
  onCellClick:
    ({formID, formKey}: {formID: string; formKey: string}) =>
    (dispatch: any) =>
      dispatch({
        type: actionTypes.ON_CELL_CLICK,
        payload: {formID, formKey},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_CELL_CLICK
        }
      }),
  onCellDblClick:
    ({formID, formKey}: {formID: string; formKey: string}) =>
    (dispatch: any) =>
      dispatch({
        type: actionTypes.ON_CELL_DOUBLE_CLICK,
        payload: {formID, formKey},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_CELL_DOUBLE_CLICK
        }
      }),
  onCardClick:
    ({
      cardData,
      cardKey,
      column,
      formKey,
      formID
    }: {
      cardData: Record<string, any>;
      cardKey: number;
      column: IKanbanDataColumn;
      formKey: string;
      formID: string;
    }) =>
    (dispatch: AppDispatch) => {
      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_CARD_CLICK,
        payload: {cardData, cardKey, column, formKey, formID, event},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_CARD_CLICK
        }
      });

      return eventPromise;
    },
  onDiagramItemClick:
    ({
      elementID,
      formKey,
      formID,
      name,
      type
    }: {
      elementID: string;
      formKey: string;
      formID: string;
      name: string;
      type: string;
    }) =>
    (dispatch: AppDispatch) =>
      dispatch({
        type: actionTypes.ON_DIAGRAM_ITEM_CLICK,
        payload: {elementID, formKey, formID, name, type},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_DIAGRAM_ITEM_CLICK
        }
      }),
  onDecorationElementClick:
    ({formID, formKey, name}: {formID: string; formKey: string; name: string}) =>
    (dispatch: AppDispatch) => {
      const {event, eventPromise} = createEventPromise();
      dispatch({
        type: actionTypes.ON_DECORATION_ELEMENT_CLICK,
        payload: {formID, formKey, name, event},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_DECORATION_ELEMENT_CLICK
        }
      });
      return eventPromise;
    },
  onDecorationElementDoubleClick:
    ({formID, formKey, name}: {formID: string; formKey: string; name: string}) =>
    (dispatch: AppDispatch) => {
      const {event, eventPromise} = createEventPromise();
      dispatch({
        type: actionTypes.ON_DECORATION_ELEMENT_DOUBLE_CLICK,
        payload: {formID, formKey, name, event},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_DECORATION_ELEMENT_DOUBLE_CLICK
        }
      });
      return eventPromise;
    },
  onDiagramItemDoubleClick:
    ({
      elementID,
      formKey,
      formID,
      name,
      type
    }: {
      elementID: string;
      formKey: string;
      formID: string;
      name: string;
      type: string;
    }) =>
    (dispatch: AppDispatch) =>
      dispatch({
        type: actionTypes.ON_DIAGRAM_ITEM_DOUBLE_CLICK,
        payload: {elementID, formKey, formID, name, type},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_DIAGRAM_ITEM_DOUBLE_CLICK
        }
      }),
  onDiagramSelectionChanged:
    ({
      formKey,
      formID,
      name,
      selectedItems
    }: {
      formKey: string;
      formID: string;
      name: string;
      selectedItems: {
        id: string;
        type: string;
      }[];
    }) =>
    (dispatch: AppDispatch) =>
      dispatch({
        type: actionTypes.ON_DIAGRAM_SELECTION_CHANGED,
        payload: {selectedItems, formKey, formID, name},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_DIAGRAM_SELECTION_CHANGED
        }
      }),
  onAppointmentElementClick:
    ({formID, formKey, appointmentData}: {formID: string; formKey: string; appointmentData: AppointmentDataType}) =>
    (dispatch: AppDispatch) => {
      const {event, eventPromise} = createEventPromise();
      dispatch({
        type: actionTypes.ON_APPOINTMENT_ELEMENT_CLICK,
        payload: {formID, formKey, event, appointmentData},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_APPOINTMENT_ELEMENT_CLICK
        }
      });
      return eventPromise;
    },

  onGroupButtonClick:
    ({formID, formKey, groupData}: {formID: string; formKey: string; groupData: GroupDataType}) =>
    (dispatch: AppDispatch) => {
      const {event, eventPromise} = createEventPromise();
      dispatch({
        type: actionTypes.ON_GROUP_BUTTON_CLICK,
        payload: {formID, formKey, event, groupData},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_GROUP_BUTTON_CLICK
        }
      });
      return eventPromise;
    },

  onItemSwipe:
    ({formID, formKey, data}: {formID: string; formKey: string; data: DataType}) =>
    (dispatch: AppDispatch) => {
      const {event, eventPromise} = createEventPromise();
      dispatch({
        type: actionTypes.ON_ITEM_SWIPE,
        payload: {formID, formKey, event, data},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_ITEM_SWIPE
        }
      });
      return eventPromise;
    },

  onItemClick:
    ({formID, formKey, data}: {formID: string; formKey: string; data: ItemDataType}) =>
    (dispatch: AppDispatch) => {
      const {event, eventPromise} = createEventPromise();
      dispatch({
        type: actionTypes.ON_ITEM_CLICK,
        payload: {formID, formKey, event, data},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_ITEM_CLICK
        }
      });
      return eventPromise;
    },

  onItemDblClick:
    ({formID, formKey, data}: {formID: string; formKey: string; data: ItemDataType}) =>
    (dispatch: AppDispatch) => {
      const {event, eventPromise} = createEventPromise();
      dispatch({
        type: actionTypes.ON_ITEM_DOUBLE_CLICK,
        payload: {formID, formKey, event, data},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_ITEM_DOUBLE_CLICK
        }
      });
      return eventPromise;
    },

  onItemHold:
    ({formID, formKey, data}: {formID: string; formKey: string; data: ItemDataType}) =>
    (dispatch: AppDispatch) => {
      const {event, eventPromise} = createEventPromise();
      dispatch({
        type: actionTypes.ON_ITEM_HOLD,
        payload: {formID, formKey, event, data},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_ITEM_HOLD
        }
      });
      return eventPromise;
    },

  onAppointmentElementDoubleClick:
    ({formID, formKey, appointmentData}: {formID: string; formKey: string; appointmentData: AppointmentDataType}) =>
    (dispatch: AppDispatch) => {
      const {event, eventPromise} = createEventPromise();
      dispatch({
        type: actionTypes.ON_APPOINTMENT_ELEMENT_DOUBLE_CLICK,
        payload: {formID, formKey, event, appointmentData},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_APPOINTMENT_ELEMENT_DOUBLE_CLICK
        }
      });
      return eventPromise;
    },
  onCardDragDropStart:
    ({
      formKey,
      formID,
      columnFromID,
      cardData,
      cardKey
    }: {
      formKey: string;
      formID: string;
      columnFromID: number;
      cardData: Record<string, any>;
      cardKey: number;
    }) =>
    (dispatch: AppDispatch) => {
      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_CARD_DRAG_DROP_START,
        payload: {formKey, formID, columnFromID, cardData, cardKey, event},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_CARD_DRAG_DROP_START
        }
      });

      return eventPromise;
    },
  onCardDragDropFinish:
    ({
      formKey,
      formID,
      columnFromID,
      columnToID,
      cardData,
      cardKey
    }: {
      formKey: string;
      formID: string;
      columnFromID: number;
      columnToID: number;
      cardData: Record<string, any>;
      cardKey: number;
    }) =>
    (dispatch: AppDispatch) => {
      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_CARD_DRAG_DROP_FINISH,
        payload: {formKey, formID, columnFromID, columnToID, cardData, cardKey, event},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_CARD_DRAG_DROP_FINISH
        }
      });

      return eventPromise;
    },
  onRowUpdated: ({formID, rowData = {}, formKey}: {formID: string; formKey: string; rowData: Record<string, any>}) => ({
    type: actionTypes.ON_ROW_UPDATED,
    payload: {formID, rowData, formKey},
    logAction: true,
    userScript: {
      eventType: USER_EVENT_TYPE.ON_ROW_UPDATED
    }
  }),
  onRowInserted: ({
    formID,
    rowData = {},
    formKey
  }: {
    formID: string;
    formKey: string;
    rowData: Record<string, any>;
  }) => ({
    type: actionTypes.ON_ROW_INSERTED,
    payload: {formID, rowData, formKey},
    logAction: true,
    userScript: {
      eventType: USER_EVENT_TYPE.ON_ROW_INSERTED
    }
  }),
  onRowDeleted: ({
    formID,
    rowData = [{}],
    formKey
  }: {
    formID: string;
    formKey: string;
    rowData: Record<string, any>[];
  }) => ({
    type: actionTypes.ON_ROW_DELETED,
    payload: {formID, rowData, formKey},
    logAction: true,
    userScript: {
      eventType: USER_EVENT_TYPE.ON_ROW_DELETED
    }
  }),
  onRowInserting:
    ({formID, rowData = {}, formKey}: {formID: string; formKey: string; rowData: Record<string, any>}) =>
    (dispatch: any) => {
      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_ROW_INSERTING,
        payload: {formID, rowData, event, formKey},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_ROW_INSERTING
        }
      });

      return eventPromise;
    },
  onRowEditing:
    ({formID, rowData = {}, formKey}: {formID: string; formKey: string; rowData: Record<string, any>}) =>
    (dispatch: any) => {
      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_ROW_EDITING,
        payload: {formID, rowData, event, formKey},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_ROW_EDITING
        }
      });

      return eventPromise;
    },
  onBeforeRowEditing:
    ({
      formID,
      rowData = {},
      formKey,
      componentInitiator = ELayoutType.TABLE,
      items
    }: {
      componentInitiator: ELayoutType;
      formID: string;
      formKey: string;
      rowData: Record<string, any>;
      items?: ILayoutItem[];
    }) =>
    (dispatch: any) => {
      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_BEFORE_ROW_EDITING,
        payload: {formID, rowData, event, formKey, componentInitiator, items},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_BEFORE_ROW_EDITING
        }
      });

      return eventPromise;
    },
  onBeforeRowInserting:
    ({
      formID,
      rowData = {},
      formKey,
      items,
      componentInitiator = ELayoutType.TABLE
    }: {
      formID: string;
      formKey: string;
      rowData: Record<string, any>;
      componentInitiator?: ELayoutType;
      items?: ILayoutItem[];
    }) =>
    (dispatch: any) => {
      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_BEFORE_ROW_INSERTING,
        payload: {formID, rowData, event, formKey, componentInitiator, items},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_BEFORE_ROW_INSERTING
        }
      });

      return eventPromise;
    },
  onRowDeleting:
    ({formID, rowData = [{}], formKey}: {formID: string; formKey: string; rowData: Record<string, any>[]}) =>
    (dispatch: any) => {
      const {event, eventPromise} = createEventPromise();
      dispatch({
        type: actionTypes.ON_ROW_DELETING,
        payload: {formID, rowData, event, formKey},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_ROW_DELETING
        }
      });

      return eventPromise;
    },
  onCustomizeRow:
    ({
      formID,
      rowData = {},
      formKey,
      rowIndex,
      isSelected,
      isExpanded,
      trHtmlElement
    }: {
      formID: string;
      formKey: string;
      name: string;
      rowIndex: number;
      isSelected: boolean;
      isExpanded: boolean;
      trHtmlElement: HTMLElement;
      rowData: Record<string, any>;
    }) =>
    (dispatch: any) => {
      dispatch({
        type: actionTypes.ON_CUSTOMIZE_ROW,
        payload: {
          formID,
          rowData,
          formKey,
          rowIndex,
          isSelected,
          isExpanded,
          trHtmlElement
        },
        userScript: {
          eventType: USER_EVENT_TYPE.ON_CUSTOMIZE_ROW
        }
      });
    },
  onCustomizeEditor:
    ({
      formID,
      formKey,
      rowData,
      editorSettings,
      name
    }: {
      formID: string;
      formKey: string;
      rowData: Record<string, any>;
      editorSettings: Record<string, any>;
      name: string;
    }) =>
    (dispatch: any) => {
      dispatch({
        type: actionTypes.ON_CUSTOMIZE_ROW_EDITOR,
        payload: {
          formID,
          formKey,
          rowData,
          editorSettings,
          name
        },
        userScript: {
          eventType: USER_EVENT_TYPE.ON_CUSTOMIZE_ROW_EDITOR
        }
      });
    },
  onCustomizeCell:
    ({
      formID,
      rowData = {},
      formKey,
      name,
      rowIndex,
      isSelected,
      isExpanded,
      tdHtmlElement
    }: {
      formID: string;
      formKey: string;
      name: string;
      rowIndex: number;
      isSelected: boolean;
      isExpanded: boolean;
      tdHtmlElement: HTMLElement;
      rowData: Record<string, any>;
    }) =>
    (dispatch: any) => {
      dispatch({
        type: actionTypes.ON_CUSTOMIZE_CELL,
        payload: {
          formID,
          rowData,
          formKey,
          name,
          rowIndex,
          isSelected,
          isExpanded,
          tdHtmlElement
        },
        userScript: {
          eventType: USER_EVENT_TYPE.ON_CUSTOMIZE_CELL
        }
      });
    },
  onCustomizeCellScheduler:
    (args: {
      formID: string;
      formKey: string;
      startDate: Date;
      endDate: Date;
      text: string;
      resources: Record<string, any>;
      isDisabled: boolean;
      isDinner: boolean;
      style: Record<string, any>;
    }) =>
    (dispatch: any) => {
      dispatch({
        type: actionTypes.ON_CUSTOMIZE_CELL_FORM,
        payload: {
          ...args,
          componentInitiator: ELayoutType.SCHEDULER
        },
        userScript: {
          eventType: USER_EVENT_TYPE.ON_CUSTOMIZE_CELL_FORM
        }
      });
    },
  onDataLoaded:
    ({formID, formKey}: {formID: string; formKey: string}) =>
    (dispatch: any, getState: () => RootState) => {
      if (checkEventActivity({formID, eventType: USER_EVENT_TYPE.ON_DATA_LOADED, state: getState()}))
        return Promise.resolve();

      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_DATA_LOADED,
        payload: {formID, event, formKey},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_DATA_LOADED
        }
      });

      return eventPromise;
    },

  onAutorefresh:
    ({formID, formKey}: {formID: string; formKey: string}) =>
    (dispatch: any, getState: () => RootState) => {
      if (checkEventActivity({formID, eventType: USER_EVENT_TYPE.ON_AUTOREFRESH, state: getState()}))
        return Promise.resolve();

      const {event, eventPromise} = createEventPromise();

      dispatch({
        type: actionTypes.ON_AUTOREFRESH,
        payload: {formID, event, formKey},
        logAction: true,
        userScript: {
          eventType: USER_EVENT_TYPE.ON_AUTOREFRESH
        }
      });

      return eventPromise;
    },

  onFieldValidation: (data: {formID: string; formKey: string; value: any; name: string}) => (dispatch: any) => {
    const {event, eventPromise} = createEventPromise();

    dispatch({
      type: actionTypes.ON_FIELD_VALIDATION,
      payload: {...data, event},
      logAction: true,
      userScript: {
        eventType: USER_EVENT_TYPE.ON_FIELD_VALIDATION
      }
    });

    return eventPromise;
  }
};
