import {BaseUserEventArgs} from './types';
import {userFunction} from '../userFunction';
import {commonResult} from '../results';

export const onAutorefresh = async (props: BaseUserEventArgs) => {
  const {items, groups, eventType, subForms, dispatch, processedForm, parentForm} = props;

  const {formData} = processedForm;

  return userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    eventType,
    processedForm,
    parentForm,
    args: []
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
