const Messages = {
  Success: {
    Saved: 'Збережено',
    Rejected: 'Скасовано',
    PasswordChanged: 'Пароль успішно змінено',
    ActivationSuccessful: 'Активація завершилася успішно',
    CheckYourEmailToCompleteReg: 'Підтвердіть ваш email для завершення реєстрації',
    Copied: 'Фільтр успішно скопійовано',
    NotCopiedFilterNotSet: 'Не скопійовано. Фільтр не встановлений',
    DocumentsPassed: 'Документи проведені',
    DocumentsUnpassed: 'Проведення документів скасовано',
    InformationCopiedSuccessfully: 'Інформація успішно скопійована'
  },

  Errors: {
    InvalidConfigNoProperty: 'Помилка конфігурації. Відсутня властивість: {0}',
    InvalidConfigWrongStructure: 'Помилка конфігурації. {0} не правильна структура',
    InvalidMaxFileSize: 'Файл занадто великий. Максимальний розмір файлу {0}',
    FormNotFound: 'Форма {0} не знайдена',
    SubFormNotFoundSysForm: 'Сабформа {0} не знайдена у списку завантажених форм',
    FieldIsRequired: "Поле {0} обов'язкове",
    FilterNotSet: 'Фільтр не встановлений',
    FieldsAreRequired: "Поля обов'язкові до заповнення: {0}",
    CustomFontsLoadingError: 'Не вдалося завантажити призначені для користувача шрифти',
    FiltersAreRequired: "Обов'язкові фільтри до заповнення: {0}",
    FiltersDateOutOfRange: 'Дата виходить за припустимі рамки: {0}',
    SmallPassword: 'Ваш пароль повинен бути більше {0} знаків',
    IsEmptyBodyFilter: 'Ви намагаєтеся зберегти порожній фільтр',
    RecordNotFound: 'Записи не знайдені.',
    PassAndConfirmDifferent: 'Паролі не співпадають',
    PassRequired: 'Поле повинно бути заповнено',
    CheckLogin: 'Перевірте логін',
    CheckPassword: 'Перевірте пароль',
    ConfirmRequired: 'Необхідно підтвердити пароль',
    AtLeastOneRowMustBeSelected: 'Виберіть хоча б один рядок',
    OnlyOneSelectedRowAllowedForThisAction:
      'Поле призначення не підтримує множинний вибір. Для даної операції повинен бути обраний тільки один рядок',
    DestinationFilterNotHaveCorrectCondition:
      'У поле призначення не передано відповідної умови фільтрації. Будь ласка, перевірте налаштування умов фільтрації',
    ListFormAndTreeViewSubFormsTypes:
      'Тільки сабформи типу ListForm, TreeView, EditForm, TileList, Kanban, NavEditForm, Scheduler і FreeForm дозволені для форм типу ListForm та TreeView',
    EditFormAndFreeFormSubFormsTypes:
      'Тільки сабформи типу ListForm, TreeView, EditForm, TileList, Kanban, NavEditForm і FreeForm  дозволені для форм типу EditForm та FreeForm',
    ReportFormAndMultiEditFormSubFormsTypes: 'Сабформи для форм типу `ReportForm` та `GroupEditForm` не дозволені',
    SchedulerFormSubFormsTypes:
      'Тільки сабформи типу ListForm, TreeView, EditForm, TileList, Kanban і NavEditForm  дозволені для форм типу Scheduler',
    EmptyRespInGetEditFormDataFunc: 'Empty response in getEditFormData function',
    PasswordAndConfirmationDoNotMatch: 'Пароль та підтвердження не збігаються',
    FillInAllTheFields: 'Заповніть всі поля',
    SpacesError: 'не може містити пробіл',
    IsIncorrect: 'невірне',
    ErrorSymbols: 'Неприпустимі символи в полі {0}',
    NoFormAttached: 'Для даної операції немає закріпленої форми',
    InvalidDate: 'Не вірний формат дати',
    DateToMustBeLess: 'Дата кінця повинна бути меншою за дату початку',
    InvalidDateEntered: 'Не вірно введена дата.',
    ErrorOccurred: 'Виникла помилка',
    InvalidFilter: 'Збережений фільтр є некоректним. Будь ласка, перестворіть його.',
    ClientScriptInitializationError: 'Помилка ініціалізації клієнтського скрипта',
    ParentFormError: 'Може викликатися лише на батьківській формі, але не на сабформі.',
    GroupDoesNotExist: 'Цієї групи {0} не існує',
    UnableToSelectRow: 'Неможливо вибрати рядок',
    DateOutOfRange: 'Дата виходить за припустимі рамки',
    IncorrectExistingPassword: 'Неправильний існуючий пароль',
    WrongSelectFieldConfigured:
      'Помилка встановлення значення: [{1}] в поле вибору "{0}". Некоректний тип даних: не можна вставляти масив у поле, яке не підтримує множинний вибір',
    WrongMultiselectSelectFieldConfigured:
      'Помилка встановлення значення: {1} в поле вибору "{0}". Некоректний тип даних: не можна вставляти не масив у поле, у якого налаштований множинний вибір',
    InvalidDefaultConfigurationButtons:
      'Некоректна конфігурація кнопок на формі, будь ласка, перевірте налаштування кнопок',
    ImportConfigurationError: 'Помилка імпорту конфігурації. {0}',
    JsonParseError: 'Розбір JSON. {0}',
    AllowedSetOnlyOneUniqueFilterValue: 'Дозволено встановлення тільки одного унікального значення фільтру',
    NoFilterConditionIsAvailableForThisField: 'Для даного поля недоступна умова фільтрації',
    UserScriptErrorTitle: 'Помилка виконання користувацького скрипта',
    ContactAdministrator: 'Зверніться до адміністратора.',
    CouldNotCopyText: 'Не вдалося скопіювати текст: {0}',
    Error: 'Помилка',
    ErrorRetrievingData: 'Помилка отримання даних',
    ErrorSavingData: 'Помилка збереження даних',
    ErrorDeletingData: 'Помилка видалення даних',
    SettingsError: 'Помилка налаштувань',
    IncorrectButtonSettings: 'Некоректні налаштування кнопок'
  },

  Warning: {
    ChangeFilterInUserScript:
      'У користувальницьких скриптах заборонено змінювати ' +
      'фільтр поки таблиця знаходиться в режимі редагування. Фільтр не застосувався.',
    CustomParamByFN: 'Поле `{0}`, вказане в `CustomParameters`, не знайдено на формі.',
    EditNestedDataOnlyInInlineMode: "Редагування вкладених об'єктів можливо тільки в рядку.",
    OnSaveRowDeprecated: 'Метод `onSaveRow` застарів, використовуйте `onRowBeforeSave` натомість.',
    OnInitializedDeprecated: 'Метод `onInitialized` застарів, використовуйте `onBeforeEditRow` натомість.',
    OnMasterSelectFilterDeprecated:
      'Метод `OnMasterSelectFilter` застарів, використовуйте `onMasterSelectFilter` натомість.',
    OnBeforeGetDataFilterDeprecated: 'Метод `OnBeforeGetData` застарів, використовуйте `onBeforeGetData` натомість.',
    AllColumnsDisabledForReordering: 'Зміна порядку колонок заборонена.',
    LoadAllWarningMessage: 'Відображено перші `{0}` рядків. Уточніть умову відбору даних',
    FormWithoutElements: 'Неможливо відкрити форму без елементів',
    AtLeastOneButtonMustBeVisibleInToolbar: 'Повинна бути видима хоча б одна кнопка в тулбарі',
    AtLeastOneButtonMustBeVisibleInMenu: 'Повинна бути видима хоча б одна кнопка в меню',
    AtLeastOneColumnMustBeVisible: 'Повинна бути видима хоча б одна колонка',
    AtLeastOneBoardMustBeVisible: 'Повинна бути видима хоча б одна дошка',
    VisibilityFilterCanNotBeTurnedOff: 'Видимість фільтру не можливо відключити, бо в нього встановлено значення'
  },

  Confirm: {
    RowsDelete: 'Видалити вибрані елементи?',
    SaveChanges: 'Зберегти внесені зміни?',
    ResetAllSettings: 'Ви впевнені що хочете скинути всі налаштування?',
    ResetColumnSettings: 'Ви впевнені що хочете скинути налаштування колонок?',
    ResetButtonsSettings: 'Ви впевнені що хочете скинути налаштування кнопок в тулбарі?',
    ClearQuickFilters: 'Ви дійсно хочете очистити всі застосовані параметри фільтрації?',
    SetIsPassed: 'Ви хочете провести документ?',
    ClearIsPassed: 'Ви хочете скасувати проведення документа?',
    ChangeFilter: 'Ви дійсно хочете замінити фільтр?',
    ChangeFavorite: 'Ви дійсно хочете прибрати ознаку "Улюблений фільтр"?',
    DeleteFilter: 'Ви дійсно хочете видалити збережений фільтр?'
  },

  Message: {
    EnterLoginAndPass: 'Введіть логін і пароль',
    Deleted: 'Видалено',
    SingIn: 'Авторизуватись',
    EnterFilterName: 'Введіть назву фільтра',
    CheckYourEmail: 'Перевірте вашу пошту для закінчення процедури відновлення пароля',
    Count: 'КІЛЬКІСТЬ',
    Avg: 'СР.ЗНАЧЕННЯ',
    Sum: 'СУММА',
    Min: 'МІН',
    Max: 'МАКС',
    FilterAddedToFavorite: 'Фільтр додано до "Улюблених"',
    FilterRemovedFromFavorite: 'Фільтр видалений з "Улюблених"',
    FilterSetAsDefault: 'Фільтр встановлений фільтром за замовчуванням',
    FilterSetAsRegular: 'Фільтр більше не є фільтром за замовчуванням',
    FilterChanged: 'Фільтр змінений',
    FilterRemoved: 'Фільтр видалений',
    FilterNameChanged: 'Назва фільтру змінена',
    WeCanNotFindPage: 'Здається, ми не можемо знайти сторінку, яку ви шукаєте.',
    SomethingWentWrong: 'Щось пішло не так!',
    HereHelpfulLinks: 'Деякі корисні посилання:',
    ErrorCode404: 'Код помилки: 404',
    Help: 'Допомога',
    UserSettings: 'Налаштування користувача',
    ShowAllSubsystems: 'Показати всі підсистеми',
    ShowMobileVersionSubsystems: 'Показати підсистеми мобільної версії',
    QuickTips: 'Швидкі поради',
    Notifications: 'Сповіщення',
    Configurator: 'Конфігуратор',
    ChangePassword: 'Змінити пароль',
    UserScriptGuide: 'Гід по користувацьким скриптам',
    About: 'Про застосунок',
    InstalledApplications: 'Встановлені застосунки',
    Profile: 'Профіль',
    Greetings: 'Вітаю, {0}!',
    CurrentVersionApplication: 'Поточна версія D5 - {0}'
  },

  Controls: {
    Application: 'Застосунок',
    Home: 'На головну',
    SingIn: 'Увійти',
    SingOut: 'Вийти',
    SingUp: 'Реєстрація',
    Login: 'Логін',
    ForgotPassword: 'Забули пароль?',
    Name: 'Імʼя',
    Email: 'Пошта',
    Email_Or_Login: 'Логін або пошта',
    Password: 'Пароль',
    Cancel: 'Скасувати',
    Close: 'Закрити',
    Clear: 'Очистити',
    Yes: 'Так',
    No: 'Ні',
    All: 'Усі',
    On: 'Ввімк',
    Off: 'Вимк',
    OK: 'OK',
    OR: 'або',
    Confirmation: 'Підтвердження',
    SelectAll: 'Виділити все',
    Select: 'Вибрати',
    SelectValue: 'Вибрати значення',
    SelectAllHint: 'Виділення усіх рядків таблиці',
    ShowTableFilterRow: 'Показати/Сховати рядок фильтрації',
    UserSettings: 'Налаштування користувача',
    PasswordChange: 'Зміна пароля',
    GeneralSettings: 'Загальні налаштування',
    TablesTreesSettings: 'Налаштування таблиць і дерев',
    EditorStylingMode: 'Режим стилів редакторів',
    Filter: 'Фільтр',
    SaveCurrentFilter: 'Зберегти поточний фільтр',
    FilterManager: 'Менеджер фільтрів',
    CopyFilterAsURL: 'Скопіювати фільтр як посилання',
    OpenFilterPanel: 'Панель фільтрації',
    RemoveFromFavorites: 'Прибрати з обраного',
    ChangeFilter: 'Змінити фільтр',
    LoadAll: 'Завантажити все',
    Report: 'Звіт',
    ReportGroup: 'Група звітів',
    DeselectAll: 'Скасувати виділення всього',
    DeselectAllHint: 'Скасування виділення всіх рядків таблиці',
    LoadingPanel: 'Завантаження...',
    SaveAndClose: 'Зберігти и закрити',
    Apply: 'Застосувати',
    RegionalSettings: 'Регіональні налаштування',
    CommonSettings: 'Загальні',
    ManageSettings: 'Керування налаштуваннями',
    ImportSettings: 'Імпорт налаштувань...',
    IHaveLoginAndPassword: 'Я маю логін і пароль',
    ExportSettings: 'Експорт налаштувань...',
    Settings: 'Налаштування',
    CalcTotals: 'Підсумковий рядок',
    CalcTotalsNoneHint: 'Розрахунок підсумкового рядка відключено',
    CalcTotalsBySelected: 'По виділеним',
    CalcTotalsBySelectedHint: 'Розрахувати підсумковий рядок по обраним записам',
    CalcTotalsAllData: 'По всім даним',
    Change: 'Змінити',
    OldPassword: 'Старий пароль',
    NewPassword: 'Новий пароль',
    ConfirmationPassword: 'Підтвердження пароля',
    TabSettings: 'Налаштування закладок',
    ResetAllSettingsHint: 'Скинути налаштування користувача',
    Add: 'Додати',
    Edit: 'Редагувати',
    View: 'Перегляд',
    Delete: 'Видалити',
    DoNotOpenByDefault: 'Не відкривати за замовчуванням',
    OpenByDefault: 'Відкривати за замовчуванням',
    AddToFavorites: 'Додати в обране',
    Copy: 'Скопіювати',
    Refresh: 'Оновити',
    IsBlank: 'Пусто',
    IsNotBlank: 'Не пусто',
    IsAnyOf: 'Будь-який з',
    IsNotAnyOf: 'Жоден з',
    Preferences: 'Налаштування',
    Export: 'Експорт',
    ExportAll: 'Експорт всіх даних',
    ExportSelected: 'Експорт обраних записів',
    Rename: 'Перейменувати',
    SaveWithOutClose: 'Зберегти без закриття',
    Save: 'Зберегти',
    SaveAndCreateNew: 'Додати & Створити ще',
    Discard: 'Скасувати',
    Send: 'Відправити',
    SendRequest: 'Відправити запит',
    ApplicationLanguage: 'Мова додатку',
    Appearance: 'Зовнішній вигляд',
    ApplicationTheme: 'Тема додатку',
    DrawerTheme: 'Тема панелі навігації',
    SystemToolbarTheme: 'Тема системної панелі',
    ApplicationDrawerSide: 'Положення меню',
    DrawerSideLeft: 'Зліва',
    DrawerSideRight: 'Справа',
    Format: 'Формат',
    ClearQuickFilters: 'Очистити всі змінені параметри фільтрації',
    ClearAll: 'Очистити все',
    RememberMe: "Запам'ятати мене",
    SetIsPassed: 'Провести документ',
    ClearIsPassed: 'Скасувати проведення документа',
    AddChild: 'Додати дочірній запис',
    ShowMore: 'Показати більше',
    ShowTitle: 'Показувати текст',
    More: 'Ще',
    OpenInNewTab: 'Відкрити в новій вкладці',
    Toolbar: 'Тулбар',
    Menu: 'Меню',
    Open: 'Відкрити',
    CopyText: 'Скопіювати текст',
    Blank: '(Порожньо)',
    Today: 'Сьогодні',
    StartWork: 'Початок роботи',
    CreateProject: 'Створення проектів',
    Orders: 'Робота с замовленнями',
    Groups: 'Групи',
    CreateList: 'Створення списків',
    Filters: 'Робота з фільтрами',
    EditFilters: 'Редагування фільтрів',
    Documentation: 'Вся документація',
    ProfileSettings: 'Налаштування профілю',
    GoBack: 'Назад',
    GoToMenu: 'Повернутися в меню',
    HideColumn: 'Сховати колонку',
    UnfixColumns: 'Відкріпити колонки',
    SortAscending: 'Сортування за зростанням',
    SortDescending: 'Сортування за спаданням',
    Sorts: 'Сортування',
    ClearSorting: 'Очистити сортування',
    ClearFiltering: 'Очистити фільтр',
    Unfix: 'Відкріпити',
    FixColumnsToTheLeft: 'Закріпити все вліво',
    DefaultFilter: 'Фільтр за замовчуванням',
    Favorite: 'В обрані',
    ModifyDate: 'Дата редагування',
    NewFilter: 'Новый фільтр',
    ShowFilterDockPanel: 'Показати панель фільтрації',
    ShowEditDockPanel: 'Показати панель редагування',
    CustomizeFields: 'Налаштування полів',
    ShowFields: 'Показувати поля',
    Default: 'Стандартні',
    SavedFilters: 'Збережені фільтри',
    Columns: 'Колонки',
    Tabs: 'Закладки',
    Buttons: 'Кнопки',
    Other: 'Інші',
    Value: 'Значення поля',
    Values: 'Значення полів',
    Search: 'Пошук',
    Sections: 'Розділ',
    Favorites: 'Обране',
    EmptyListText: 'Вибрані фільтри відсутні',
    InterfaceSize: 'Розмір інтерфейсу',
    Language: 'Мова',
    SelectCamera: 'Оберіть камеру',
    Contains: 'Пошук за збiгом',
    StartsWith: 'Пошук за першими символами',
    ByWords: 'Пошук за словами',
    DisplayName: 'Відображати наіменування',
    DisplayKeyValue: 'Відображати ключове значення',
    Help: 'Довідка',
    Details: 'Детальніше',
    Equals: 'Дорівнює',
    DoesNotEquals: 'Не дорівнює',
    LessThan: 'Менше ніж',
    GreaterThan: 'Більше ніж',
    LessOrEqualTo: 'Менше або дорівнює',
    GreaterOrEqualTo: 'Більше або дорівнює',
    Between: 'Між',
    ContainsSmth: 'Містить',
    StartsWithSmth: 'Починається',
    ByWordsSearch: 'По словах',
    Boards: 'Дошки',
    AllVisible: 'Всі видимі',
    ClearValueMode: 'Режим очистки значення',
    HorizontalSeparatorLines: 'Горизонтальні розділові лінії',
    VerticalSeparatorLines: 'Вертикальні розділові лінії',
    AlternativeLinesColor: 'Альтернативний колір строк',
    DefaultSt: 'По замовчуванню',
    Show: 'Показувати',
    Hide: 'Не показувати',
    Version: 'Версія',
    Title: 'Назва',
    Description: 'Опис',
    NotFoundSubsystem: 'Доступні сабсистеми відсутні',
    NotFoundButtons: 'Доступні кнопки відсутні',
    PreviewFile: 'Перегляд файлу',
    ShowAdminPanel: 'Адмін панель',
    ShowConfiguratorPanel: 'Конфігураційна панель',
    ShowMainPage: 'До застосунку',
    Column: 'Колонка',
    Dock: 'Док панель',
    LastOpenedWindow: 'Останнє відкрите вікно',
    BrowserNewWindowOpeningMode: 'Режим відкриття нового вікна',
    RenameFilter: 'Перейменувати фільтр',
    EnterFilterName: 'Введіть імʼя фільтра',
    Filtrate: 'Фільтрувати',
    FiltersSaved: 'Збережені',
    Done: 'Готово',
    SaveFilter: 'Зберегти фільтр',
    DetailedInformation: 'Детальна інформація',
    ErrorCode: 'Код помилки',
    ErrorText: 'Текст помилки',
    ResponseId: 'Ідентифікатор відповіді',
    color: 'Змінити колір шрифту',
    background: 'Змінити колір фону',
    SingleFormMode: 'Режим єдиної форми',
    RegularFormMode: 'Звичайний режим',
    SelectFile: 'Обрати файл',
    UploadFile: 'Завантажити файл',
    OpenInBrowser: 'Відкрити файл в браузері',
    Actions: 'Дії',
    NoDataToDisplay: 'Немає даних для відображення',
    Create: 'Створити',
    ResetColumnsSettings: 'Скинути налаштування колонок',
    ResetButtonsSettings: 'Скинути налаштування кнопок',
    ResetAllSettings: 'Скинути усі налаштування форми'
  },
  AdditionalText: {
    YouDontHaveNotifications: 'У вас немає сповіщень',
    UnsavedChanges: 'У вас є незбережені зміни! Ви хочете зберегти або скасувати ці зміни?',
    UseYourAccount: 'або використовуйте Ваш аккаунт',
    EnterYourDetails: 'Введіть ваші персональні дані і почніть подорож з нами',
    NewPassword: 'Новий пароль',
    ToKeepConnected: 'Щоб продовжити роботу, зайдіть під своїми персональними даними',
    WelcomeBack: 'З поверненням!',
    CreateAccount: 'Створити аккаунт',
    RestorePassword: 'Відновлення паролю',
    ByDefault: 'За замовчуванням',
    InSubsystem: 'У підсистемі',
    ModifyDate: 'Дата редагування',
    D5: 'D5',
    Insert: 'Створення',
    Edit: 'Редагування',
    View: 'Перегляд',
    ResourceNotFound: 'Ресурс не знайдено',
    Login: 'Вхід',
    SignInWith: 'або увійти за допомогою'
  }
};

export default Messages;
