import React, {createContext, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {
  urlActivation,
  urlChangePass,
  urlCheckLogin,
  urlRestorePass,
  urlSignIn,
  urlSignOut,
  urlSignUp,
  urlUserInfo
} from './services/baseUrl';
import {ADMIN_PANEL_ROUTE, CONFIGURATOR_PANEL_ROUTE, LOGIN_ROUTE, system} from './services/objects';
import {isEmptyObject} from 'services/SecondaryMethods/typeUtils';
import {Spinner} from 'components/spinner';
import useHistory from 'components/useHistory';
import {IUser} from 'services/interfaces/global-interfaces';
import useInitAxios from './useInitAxios';
import {checkAppSettings, convertedDataResponse} from './services/SecondaryMethods/userSettings';
import {getLastActiveReturnUrlService, getReturnUrl} from 'utilsOld/returnUrl';
import {checkNewWindowOpenedMode, routeFromHash, servicePrefixFromRoute} from './utilsOld/routes/routes';

export interface AuthContextInterface {
  isAuth: boolean;
  user: IUser | null;
  signIn: (login: string, password: string) => Promise<void>;
  activationFunction: (code: string) => Promise<void>;
  restorePassword: (email: string, emailOrLogin: string) => Promise<void>;
  changePassword: (credentials: {
    ActivationCode?: string;
    OldPassword?: string;
    Password: string;
    Login?: string;
  }) => Promise<void>;
  signOut: () => void;
  getUserProfileInfo: () => Promise<{EMail: string}>;
  signUp: (args: {Login: string; Name: string; EMail: string; Password: string}) => Promise<void>;
  checkLogin: () => Promise<{ResponseCode: '000' | '401'}>;
}

const {
  INPUT_PLACEHOLDERS: {EMAIL}
} = system;

const DELAY_CHECK_LOGIN = 5 * 60 * 1000; // 5 хвилин у мілісекундах

const AuthContext = createContext<AuthContextInterface | null>(null);
const useAuth = (): Partial<AuthContextInterface> => useContext(AuthContext) || {};

export const clearStorage = () => {
  localStorage.removeItem(system.current_user);
};

const AuthProvider: React.FC<{children?: React.ReactNode}> = ({children}) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const processLogin = async (newUser: IUser) => {
    setUser(newUser);
    navigateToReturnUrl();
  };

  function navigateToReturnUrl() {
    const route = routeFromHash(window.location.hash);
    const lastService = getLastActiveReturnUrlService();
    const service = servicePrefixFromRoute(window.location.hash);

    const returnRoute = getReturnUrl(LOGIN_ROUTE === route ? lastService : service, route);

    //TODO для таски https://teamtrack.macc.com.ua/view.php?id=97673
    //після правки потрібно видалити
    console.log('navigateToReturnUrl', {route, returnRoute, lastService, service});
    if ([CONFIGURATOR_PANEL_ROUTE, ADMIN_PANEL_ROUTE, LOGIN_ROUTE, '/'].includes(route)) {
      history.replace(returnRoute || '/');
    }
  }

  const signIn = async (login: string, password: string) => {
    const headerDefault = {};
    const options = {
      method: 'POST',
      headers: headerDefault,
      url: urlSignIn,
      data: {
        [system.REQUEST]: {
          [system.SYS_LOGIN]: {Login: login, Password: password}
        }
      }
    } as const;

    await axios(options).then(async ({data}) => {
      if (data.ResponseCode !== '000') {
        return Promise.reject(new Error(data.ResponseText));
      }
      const userProfile = await getUserProfileInfo();
      const newUser = convertedDataResponse(data.Response, login, userProfile);

      localStorage.setItem(system.current_user, JSON.stringify(newUser));
      sessionStorage.setItem(system.current_user, JSON.stringify(newUser));

      checkAppSettings();
      checkNewWindowOpenedMode();
      await processLogin(newUser);
    });
  };
  const getUserProfileInfo = async () => {
    const headerDefault = {};
    const options: any = {
      method: 'POST',
      headers: headerDefault,
      data: {
        Columns: ['EMail']
      },
      url: urlUserInfo
    } as const;

    try {
      const {data} = await axios(options);
      if (data.ResponseCode !== '000') {
        throw new Error(data.ResponseText);
      }
      return data.Response[system.SYS_PROFILE][0];
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const checkLogin = async () => {
    const options = {
      method: 'GET',
      url: urlCheckLogin
    } as const;

    const {data} = await axios(options);
    return data;
  };

  const signUp = (creds: {Login: string; Name: string; EMail: string; Password: string}) => {
    clearStorage();
    const headerDefault = {};

    const option = {
      method: 'POST',
      headers: headerDefault,
      url: urlSignUp,
      data: {
        [system.REQUEST]: {
          [system.SYS_LOGIN]: creds
        }
      }
    } as const;

    return axios(option).then(({data}) => {
      if (data.ResponseCode !== '000') {
        return Promise.reject(new Error(data.ResponseText));
      }
      return Promise.resolve(data.Response);
    });
  };

  //  user activation
  const activationFunction = (code: string) => {
    clearStorage();

    const options = {
      method: 'POST',
      url: urlActivation,
      contentType: 'Application/jsoncharset=UTF-8',
      data: {[system.REQUEST]: {[system.SYS_LOGIN]: {ActivationCode: code}}}
    } as const;

    return axios(options).then(({data}) => {
      if (data.ResponseCode !== '000') {
        return Promise.reject(new Error(data.ResponseText));
      }
      return Promise.resolve(data);
    });
  };

  const restorePassword = (value: string, emailOrLogin: string) => {
    const body = emailOrLogin === EMAIL ? {EMail: value} : {Login: value};
    clearStorage();
    const options = {
      method: 'POST',
      url: urlRestorePass,
      contentType: 'Application/jsoncharset=UTF-8',
      data: {[system.REQUEST]: {[system.SYS_LOGIN]: body}}
    } as const;

    return axios(options).then(({data}) => {
      if (data.ResponseCode !== '000') {
        return Promise.reject(new Error(data.ResponseText));
      }
      return Promise.resolve(data.Response);
    });
  };
  //  user changePassword
  const changePassword = (credentials: {
    ActivationCode?: string;
    Password: string;
    OldPassword?: string;
    Login?: string;
  }) => {
    const options = {
      method: 'POST',
      url: urlChangePass,
      contentType: 'Application/jsoncharset=UTF-8',
      data: {[system.REQUEST]: {[system.SYS_LOGIN]: credentials}}
    } as const;

    return axios(options).then(({data}) => {
      if (data.ResponseCode !== '000') {
        return Promise.reject(new Error(data.ResponseText));
      }
      return Promise.resolve(data.Response);
    });
  };

  const signOut = () => {
    const options = {
      method: 'POST',
      url: urlSignOut
    } as const;

    axios(options);
    clearStorage();
    setUser(null);
    localStorage.setItem(system.is_sing_out, 'true');
  };

  useInitAxios({signOut});

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        await checkLogin();
      } catch {
        return setLoading(false);
      }
      setLoading(false);
    }, DELAY_CHECK_LOGIN);

    return () => clearInterval(interval); // Очищаємо інтервал при зміні компонента
  }, []);

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem(system.current_user) || '{}') as IUser;

    if (isEmptyObject(currentUser)) {
      return setLoading(false);
    }

    (async function () {
      let resp: any = {};
      try {
        resp = await checkLogin();
      } catch {
        return setLoading(false);
      }

      if (resp.ResponseCode === '000') {
        await processLogin(currentUser);
      }

      setLoading(false);
    })();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuth: !!user,
        user,
        signIn,
        signUp,
        signOut,
        checkLogin,
        changePassword,
        restorePassword,
        activationFunction,
        getUserProfileInfo
      }}
    >
      {loading ? <Spinner /> : children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
export {useAuth};
