import {Column} from 'components/TableCoreOld/columns/index';
import {cellStyle, getMaxHeight} from './cellMethods';
import {getSysDisplayField} from 'utilsOld/sysFormUtils';
import {isDefined} from 'services/SecondaryMethods/typeUtils';
import HeaderFilterDataSource from './HeaderFilterDataSource';
import {Messages} from 'services/lang/messages';
import {getLookupProps} from './utils';

export default class SelectColumn extends Column {
  headerFilterValueGetter = row => {
    return {text: row[getSysDisplayField(this.field.name)], value: row[this.field.name]};
  };

  headerFilterLoadFun = instance => {
    const ds = new HeaderFilterDataSource({
      loadRows: instance.getAllRowsData.bind(instance),
      valueGetter: this.headerFilterValueGetter,
      filterEmpty: true
    });
    return () => {
      return (
        ds
          .createLoadFun()()
          //дублируется (Blank). Компонент сам добавляет такое значение по этому фильтруем
          .filter(item => isDefined(item.value))
          //у справочников ключи должны быть такие, как в компоненте
          .map(item => ({
            [this.field.getKeyObjFieldName()]: item.value,
            [this.field.displayField]: item.text
          }))
      );
    };
  };

  prop() {
    let dictObj = this.field.getLinkedName();
    let dictKeyFld = this.field.getKeyObjFieldName();
    let dictDisplayFld = this.field.displayField;
    return {
      ...super.prop(),
      ...getLookupProps({
        calculateDisplayValue: getSysDisplayField(this.field.name),
        fieldName: this.field.name,
        dictKeyFld,
        dictObj,
        dictDisplayFld,
        sortValues: this.field.sortValues
      }),
      isAnyOf: false,
      cellTemplate: (cellElement, {displayValue, column}) => {
        if (displayValue) {
          const container = document.createElement('span');

          let maxLineCount = column.userData.maxLineCount;

          /**
           * need for adding 3 dots to the end of last row in cell
           */
          const lineClampValue = maxLineCount !== 0 ? `${maxLineCount}` : '1';
          /**
           * set max height for text cell according to lineCount
           * @type {string}
           * */
          const maxHeight = getMaxHeight(maxLineCount);

          cellStyle(container, lineClampValue, maxHeight);
          container.append(displayValue);
          cellElement.append(container);
        }
      },
      headerFilter: this.createHeaderFilter(),
      placeholder: Messages.Controls.Search
    };
  }
}
