import {currentFormActions, updateCurrentForm} from 'services/currentForms/actions';
import {OnFilterValueChanged} from 'services/currentForms/types';
import {formatToAPI, getFilterName} from 'services/SecondaryMethods/filterUtils';
import {userScriptActions} from 'services/currentForms/userScriptActions';
import {toggleEditDockPanel, toggleFilterPanel, toggleSmartFolderPanel} from 'services/secondaryDrawer/actions';
import {
  BUTTON_TYPE,
  FILTER_OPERATIONS,
  FILTER_OPERATIONS_ID,
  FormType
} from 'services/interfaces/global-interfaces';
import FormEventQueue from 'utilsOld/formEventQueue/FormEventQueue';
import {actionTypes} from 'services/currentForms/actionTypes';
import {toggleButtonOption} from 'services/tables/actions';
import {FilterPanelType} from 'services/secondaryDrawer/types';
import {FilterField, SysFormFilterFieldCollection, SysFormWrapper} from 'utilsOld/systemObjects';
import {FilterStateObject} from 'services/filters/types';
import {isEmptyValue} from 'services/SecondaryMethods/typeUtils';
import {getRequired} from './initSubFormData';
import {ELayoutType} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {AppDispatch} from '../../store';
import {system} from '../../services/objects';

const {EDIT_DOCK_PANEL} = BUTTON_TYPE;

interface OnFieldValueChangeFuncParams {
  dispatch: any;
  formID: string;
  formKey: string;
  eventQueue: FormEventQueue;
}

export const createOnFieldValueChangeFunc = ({dispatch, formID, formKey, eventQueue}: OnFieldValueChangeFuncParams) => {
  return ({
    value,
    displayValue,
    dataField,
    relativeField,
    withUSEvent
  }: {
    value: any;
    displayValue: string | undefined;
    dataField: string;
    relativeField?: {fieldName: string; value: any};
    withUSEvent?: boolean;
  }) => {
    let newValue = value;

    dispatch(updateCurrentForm({formID, options: {isModified: true, isUserModified: undefined}}));

    eventQueue.add({
      name: actionTypes.ON_FIELD_VALUE_CHANGED,
      job: () => {
        return dispatch(
          userScriptActions.onValueChanged({
            formID,
            name: dataField,
            newValue,
            displayValue,
            formKey,
            relativeField,
            withUSEvent
          })
        );
      }
    });
  };
};

export const createOnFilterValueChangedFunc = (
  dispatch: any,
  formID: string,
  formKey: string
): OnFilterValueChanged => {
  return ({relativeField, value, operation, field, displayValue, hasIsBlank = false}) => {
    const filterName = getFilterName({
      objectFieldIDName: field.objectFieldIDName,
      name: field.name
    });

    dispatch(
      userScriptActions.onFltValChanged({
        formID,
        name: field.name,
        value,
        operation,
        filterName,
        formKey,
        relativeField,
        displayValue,
        sender: undefined,
        hasIsBlank
      })
    );
  };
};

export const getDockPanelEvents = (dispatch: any, formID: string, formKey: string) => {
  const dockPanelProps = {
    formID: formID,
    formKey: formKey,
    drawerKey: formKey,
    open: true,
    panelType: FilterPanelType
  };
  return {
    showEditDockPanel: () => {
      dispatch(toggleButtonOption(formKey, EDIT_DOCK_PANEL, 'isPressed'));
      dispatch(
        toggleEditDockPanel({
          ...dockPanelProps,
          drawerKey: formKey + '_right'
        })
      );
    },
    showFilterDockPanel: () => dispatch(toggleSmartFolderPanel(dockPanelProps)),
    showFilter: () => dispatch(toggleFilterPanel(dockPanelProps))
  };
};

export const toID = (id: FILTER_OPERATIONS) => {
  const map: Record<string, number> = {
    [FILTER_OPERATIONS.equal]: FILTER_OPERATIONS_ID.equal,
    [FILTER_OPERATIONS.notequal]: FILTER_OPERATIONS_ID.notequal,
    [FILTER_OPERATIONS.less]: FILTER_OPERATIONS_ID.less,
    [FILTER_OPERATIONS.greater]: FILTER_OPERATIONS_ID.greater,
    [FILTER_OPERATIONS.lessorequal]: FILTER_OPERATIONS_ID.lessorequal,
    [FILTER_OPERATIONS.greaterorequal]: FILTER_OPERATIONS_ID.greaterorequal,
    [FILTER_OPERATIONS.contains]: FILTER_OPERATIONS_ID.contains,
    [FILTER_OPERATIONS.between]: FILTER_OPERATIONS_ID.between,
    [FILTER_OPERATIONS.isanyof]: FILTER_OPERATIONS_ID.isanyof,
    [FILTER_OPERATIONS.isnotanyof]: FILTER_OPERATIONS_ID.isnotanyof,
    [FILTER_OPERATIONS.isblank]: FILTER_OPERATIONS_ID.isblank,
    [FILTER_OPERATIONS.isnotblank]: FILTER_OPERATIONS_ID.isnotblank,
    [FILTER_OPERATIONS.startwith]: FILTER_OPERATIONS_ID.startwith,
    [FILTER_OPERATIONS.bywords]: FILTER_OPERATIONS_ID.bywords
  };
  return map[id];
};

export const toSymbol = (operation: FILTER_OPERATIONS_ID) => {
  const map: Record<number, string> = {
    [FILTER_OPERATIONS_ID.equal]: FILTER_OPERATIONS.equal,
    [FILTER_OPERATIONS_ID.notequal]: FILTER_OPERATIONS.notequal,
    [FILTER_OPERATIONS_ID.less]: FILTER_OPERATIONS.less,
    [FILTER_OPERATIONS_ID.greater]: FILTER_OPERATIONS.greater,
    [FILTER_OPERATIONS_ID.lessorequal]: FILTER_OPERATIONS.lessorequal,
    [FILTER_OPERATIONS_ID.greaterorequal]: FILTER_OPERATIONS.greaterorequal,
    [FILTER_OPERATIONS_ID.contains]: FILTER_OPERATIONS.contains,
    [FILTER_OPERATIONS_ID.between]: FILTER_OPERATIONS.between,
    [FILTER_OPERATIONS_ID.isanyof]: FILTER_OPERATIONS.isanyof,
    [FILTER_OPERATIONS_ID.isnotanyof]: FILTER_OPERATIONS.isnotanyof,
    [FILTER_OPERATIONS_ID.isblank]: FILTER_OPERATIONS.isblank,
    [FILTER_OPERATIONS_ID.isnotblank]: FILTER_OPERATIONS.isnotblank,
    [FILTER_OPERATIONS_ID.startwith]: FILTER_OPERATIONS.startwith,
    [FILTER_OPERATIONS_ID.bywords]: FILTER_OPERATIONS.bywords
  };

  return map[operation];
};

export const checkRequiredFilter = (
  sysFormWrapper: SysFormWrapper,
  storeFilters: Record<string, FilterStateObject>
) => {
  const collection = new SysFormFilterFieldCollection(sysFormWrapper.filterFields || []);
  return !!collection.find(filter => {
    const filterHasNotValue = isEmptyValue(storeFilters?.[filter.Name]?.value);
    const filterField = new FilterField(filter);
    return getRequired(sysFormWrapper)(filterField, !!filter.IsRequired) && filterHasNotValue;
  });
};

export const checkIfShowFilterPanel = (
  sysFormWrapper: SysFormWrapper,
  storeFilters: Record<string, FilterStateObject>
) => {
  return sysFormWrapper.isFilterOnShow || checkRequiredFilter(sysFormWrapper, storeFilters);
};

export const getAPIFilterWithMasterFilter = (
  sysFormWrapper: SysFormWrapper,
  storeFilters: Record<string, any>,
  masterFilter: Record<string, any>,
  hasMasterFilter: boolean
) => {
  const apiFilter = formatToAPI({
    storeFilters,
    sysForm: sysFormWrapper.asRaw()
  });

  if (hasMasterFilter) {
    return {...apiFilter, ...masterFilter};
  }

  return apiFilter;
};

export function componentInitiatorByFormType(formType: FormType) {
  switch (formType) {
    case FormType.LIST_VIEW:
      return ELayoutType.ListView;
    case FormType.TILE_LIST:
      return ELayoutType.TileList;
    case FormType.KANBAN:
      return ELayoutType.KANBAN;
    default:
      return undefined;
  }
}

export const checkEditableEvents = (event: any) => {
  // Отримати код натиснутої клавіші
  const keyCode = event.keyCode;

  // Отримати тип, що вказує на те, що елементи вирізали чи вставили
  const type = event.type;
  if (type === 'cut' || type === 'paste') return true;

  // Перевірка чи введений символ
  if ((keyCode >= 65 && keyCode <= 90) || // a-z (а-я) без різниці в регістрі
    (keyCode >= 48 && keyCode <= 57) || // 0-9
    (keyCode >= 96 && keyCode <= 105) || // Numpad 0-9
    (keyCode >= 186 && keyCode <= 222)) { // Спеціальні символи на клавіатурі
    return true;
  }

  // Перевірка натискання Enter
  if (keyCode === 13) {
    return true;
  }

  // Перевірка натискання Backspace
  if (keyCode === 8) {
    return true;
  }

  // Перевірка натискання Space
  return keyCode === 32;
}

interface IDataAfterEditProps {
  dispatch: AppDispatch,
  selectedItems: Record<string, any>[],
  keyField: string,
  formID: string,
  dataAfterEdit: Record<string, any>,
  mode: string;
}

export const setDataAfterEdit = ({
  dispatch,
  selectedItems,
  keyField,
  formID,
  dataAfterEdit,
  mode
}: IDataAfterEditProps) => {
  if (mode === system.FORM_EDIT_CREATE_MODE.EDIT) {
    const updItems = selectedItems.filter((sItem) => sItem[keyField] !== dataAfterEdit[keyField])
    updItems.push(dataAfterEdit);
    const selectedKeys = updItems.map((item: Record<string, any>) => item[keyField]);
    dispatch(
      currentFormActions.selectRow({
        formID,
        rowData: updItems,
        selectedRowKeys: selectedKeys
      })
    );
  }
}
