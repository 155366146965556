class OnInitLookupFieldShared {
  private _filters: Record<string, any> | undefined | null;
  private _dataSource: Record<string, any> | undefined;

  setFilters(key: string, filters: Record<string, any> | null) {
    this._filters = {
      ...this._filters,
      [key]: filters
    };
  }

  setDataSource(key: string, dataSource: Array<any>) {
    this._dataSource = {
      ...this._dataSource,
      [key]: dataSource
    };
  }

  getDataSource(key: string) {
    return this._dataSource?.[key];
  }

  getFilters(key: string) {
    return this._filters?.[key];
  }
}

const onInitLookupFieldShared = new OnInitLookupFieldShared();

export const createStorageKey = (formID: string | undefined, dataField: string) => {
  return `${formID}-${dataField}`;
};

export default onInitLookupFieldShared;
