import {
  ButtonColorScheme,
  ButtonCreators,
  ButtonStylingMode,
  ELayoutType,
  IButtonOptions,
  ILayoutItem
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {isDefined} from 'services/SecondaryMethods/typeUtils';
import {DefaultButtonOptions} from 'utilsOld/toolbar_buttons';
import {system} from 'services/objects';
import {BUTTON_POSITION, BUTTON_TYPE} from 'services/interfaces/global-interfaces';

/**
 * создает системные кнопки
 * @param id {number}
 * @param disabled {boolean}
 * @param name {string}
 * @param parentButton {FormButton}
 * @param onClickCustom {Function}
 */
export const createSystemButton = ({
  name,
  parentButton,
  onClickCustom,
  id,
  disabled
}: {
  name: keyof typeof DefaultButtonOptions;
  parentButton: {id: number; formID: string; position: BUTTON_POSITION};
  onClickCustom: () => {};
  id?: number;
  disabled?: boolean;
}): ILayoutItem<IButtonOptions> => {
  // @ts-ignore
  const {buttonType, text, order, iconType, id: defId, disabled: defDisabled} = DefaultButtonOptions[name];
  const isDisabled = isDefined(disabled) ? disabled : defDisabled;

  return {
    creator: ButtonCreators.FORM,
    id: id || defId,
    groupID: parentButton.id,
    parentID: parentButton.id,
    isReadOnly: false,
    isVisible: true,
    itemType: ELayoutType.BUTTON,
    name,
    order: order,
    options: {
      colorScheme: ButtonColorScheme.Default,
      stylingMode: ButtonStylingMode.None,
      defaultTitle: text,
      defaultIcon: iconType,
      buttonType: buttonType,
      actionFormID: undefined,
      actionFormName: undefined,
      actionFormObjectName: undefined,
      actionFormType: undefined,
      actionFormViewMode: undefined,
      buttonFillingRules: undefined,
      clickEventName: undefined,
      customOperation: undefined,
      customParameters: undefined,
      buttonWidth: undefined,
      disabled: isDisabled,
      formID: parentButton.formID,
      formOpenMode: undefined,
      icon: iconType,
      isHideInOtherActions: false,
      onClick: onClickCustom,
      position: parentButton.position,
      title: text,
      titleType: system.TITLE_TYPE.ICON_AND_TITLE,
      width: undefined,
      isEditButtonByDefault: false,
      beginGroup: false,
      isCustomizable: false,
    }
  };
};

/**
 * создает кнопки с filterTemplates
 * @param id {number}
 * @param buttonType {number}
 * @param name {string}
 * @param parentButton {FormButton}
 * @param onClickCustom {Function}
 * @param index {number}
 */
export const createFilterTemplateButtons = ({
  name,
  parentButton,
  onClickCustom,
  id,
  index
}: {
  name: string;
  parentButton: ILayoutItem<IButtonOptions>;
  onClickCustom: () => void;
  id: number;
  index: number;
}): ILayoutItem<IButtonOptions> => {
  //берем order последнего элемента и добавяем индекс входящего элемента
  const generateOrder = DefaultButtonOptions[system.SYSTEM_BUTTONS.COPY_FILTER_AS_URL].order + index + 1;
  return {
    creator: ButtonCreators.FORM,
    id,
    groupID: parentButton.id,
    parentID: parentButton.id,
    isReadOnly: false,
    isVisible: true,
    itemType: ELayoutType.BUTTON,
    name,
    order: generateOrder,
    options: {
      defaultTitle: name,
      defaultIcon: '',
      beginGroup: index === 0,
      buttonType: BUTTON_TYPE.SELECT_FILTER_TEMPLATE,
      actionFormID: undefined,
      actionFormName: undefined,
      actionFormObjectName: undefined,
      actionFormType: undefined,
      actionFormViewMode: undefined,
      buttonFillingRules: undefined,
      clickEventName: undefined,
      colorScheme: ButtonColorScheme.Default,
      stylingMode: ButtonStylingMode.None,
      customOperation: undefined,
      buttonWidth: undefined,
      customParameters: undefined,
      disabled: false,
      formID: parentButton.options.formID,
      formOpenMode: undefined,
      icon: '',
      isHideInOtherActions: false,
      onClick: onClickCustom,
      position: parentButton.options.position,
      title: name,
      titleType: system.TITLE_TYPE.ICON_AND_TITLE,
      width: undefined,
      isEditButtonByDefault: false,
      isCustomizable: false,
    }
  };
};

export const createReportGroupButton = ({
  name,
  parentButton,
  onClickCustom,
  dialogActionFormName,
  dialogActionFormID,
  id,
  index,
  title
}: {
  name: string;
  parentButton: ILayoutItem<IButtonOptions>;
  onClickCustom: () => void;
  id: number;
  index: number;
  dialogActionFormName?: string;
  dialogActionFormID?: string;
  title: string;
}): ILayoutItem<IButtonOptions> => {
  const ReportGroup = 'reportGroup';

  const generateOrder = DefaultButtonOptions[ReportGroup].order + index + 1;
  const generateID = DefaultButtonOptions[ReportGroup].id + index + 1;
  return {
    creator: ButtonCreators.FORM,
    id: generateID,
    groupID: parentButton.id,
    parentID: parentButton.id,
    isReadOnly: false,
    isVisible: true,
    itemType: ELayoutType.BUTTON,
    name,
    order: generateOrder,
    options: {
      defaultTitle: title,
      defaultIcon: '',
      beginGroup: index === 0,
      buttonType: BUTTON_TYPE.REPORT_GROUP,
      actionFormID: dialogActionFormID || undefined,
      attachedReportId: id,
      actionFormName: dialogActionFormName || undefined,
      actionFormObjectName: undefined,
      actionFormType: undefined,
      actionFormViewMode: undefined,
      buttonFillingRules: undefined,
      clickEventName: undefined,
      customOperation: undefined,
      buttonWidth: undefined,
      customParameters: undefined,
      disabled: false,
      formID: parentButton.options.formID,
      formOpenMode: parentButton.options.formOpenMode,
      icon: '',
      isHideInOtherActions: false,
      onClick: onClickCustom,
      position: parentButton.options.position,
      title,
      colorScheme: ButtonColorScheme.Default,
      stylingMode: ButtonStylingMode.None,
      titleType: system.TITLE_TYPE.ICON_AND_TITLE,
      width: undefined,
      isEditButtonByDefault: false,
      isCustomizable: false,
    }
  };
};
