import deepEqual from 'fast-deep-equal';
import {current, finishDraft, isDraft, original} from 'immer';
import {updateCurrentScreen} from 'services/currentForms/actions';
import store from 'store';
import {CommonResult} from 'middlewares/userScript/inner-interfaces';
import {FilterDocPanel, FormObject} from 'middlewares/userScript/utils/createFormObject';
import {IButtonOptions, IGroupOptions, ILayoutItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {updateTables} from 'services/tables/actions';
import {GridColumn} from 'components/TableCoreOld/column-interfaces';
import {TableValuesAction} from 'services/tables/interfaces';
import {isArray, isDefined, isEmptyObject, isObject} from 'services/SecondaryMethods/typeUtils';
import {CurrentFormState} from 'services/currentForms/types';
import {setEditorButtons} from 'services/editorButtons/actions';
import {setFilters} from 'services/filters/combinedActions';
import {focusItem} from 'services/currentForms/complexActions';
import {FilterStateObject} from 'services/filters/types';
import {updateSchedulers} from 'services/scheduler/reducer';
import {SchedulerResource} from 'components/widgets/Scheduler/types';
import {setListViewItems} from '../../services/listView/reducer';
import {setTileViewItems} from '../../services/tileView/reducer';
import {KanbanState, setCardItems} from '../../services/kanban/reducer';
import {SysFormType} from '../../utilsOld/systemObjects';
import {ElementUserSettingsObj, setElementsUserSettings} from '../../services/elementsUserSettings/reducer';

interface CurrentStateResolved {
  groups?: ILayoutItem<IGroupOptions>[];
  items?: ILayoutItem[];
  title?: string;
  isModified?: boolean;
}

function getCurrentFormState(
  {formItems, formGroups}: Pick<CommonResult, 'formGroups' | 'formItems'>,
  readOnly?: boolean,
  isReadOnlyFormByAutoRefresh?: boolean
): CurrentStateResolved {
  let result: CurrentStateResolved = {};

  const isReadOnlyFormChanged =
    formItems.get().some(item => !!item.isReadOnlyForm !== !!readOnly) || isReadOnlyFormByAutoRefresh;

  if (formGroups.modified) {
    result.groups = formGroups.get();
  }

  if (formItems.modified) {
    result.items = formItems.get();
  }

  //если хоть у одного айтема isReadOnlyForm !== readOnly нужно засетить для всех
  if (isReadOnlyFormChanged) {
    result.items = setItemsReadOnlyForm(formItems.get(), readOnly, isReadOnlyFormByAutoRefresh);
  }

  return result;
}

const getTableValues = (
  result: Pick<CommonResult, 'buttons' | 'columns' | 'formKey' | 'rowsToUpdate' | 'expandedState' | 'summary'>[]
) => {
  return result.map((el): TableValuesAction => {
    const {buttons, columns, formKey, rowsToUpdate, expandedState, summary} = el;
    let res: TableValuesAction = {formKey};
    if (buttons?.modified) {
      res.buttons = buttons.get();
    }
    if (summary?.modified) {
      res.summary = summary.get();
    }
    if (Array.isArray(columns) && columns.length) {
      res.columns = columns as GridColumn[];
    }
    if (Array.isArray(rowsToUpdate) && rowsToUpdate.length) {
      res.rowsToUpdate = rowsToUpdate;
    }
    if (expandedState?.length) {
      res.expandedState = expandedState;
    }

    return res;
  });
};

const getFilterValues = (result: Pick<CommonResult, 'filters' | 'formKey'>[]) => {
  return result.reduce(
    (res, {filters, formKey}) => {
      if (filters?.modified) {
        return [
          ...res,
          {
            filter: filters.get(),
            formKey
          }
        ];
      }
      return res;
    },
    [] as Array<{
      formKey: string;
      filter: Record<string, FilterStateObject>;
    }>
  );
};

const getCardItems = (
  result: Pick<CommonResult, 'listViewItems' | 'kanbanCardItems' | 'tileViewItems' | 'formKey'>[]
) => {
  return result.reduce(
    (res, {listViewItems, kanbanCardItems, tileViewItems, formKey}) => {
      if (kanbanCardItems?.modified) {
        return {
          ...res,
          kanbanState: {
            formKey,
            cardItems: kanbanCardItems.get()
          }
        };
      }
      if (listViewItems?.modified) {
        return {
          ...res,
          listViewState: {
            formKey,
            items: listViewItems.get()
          }
        };
      }

      if (tileViewItems?.modified) {
        return {
          ...res,
          tileViewState: {
            formKey,
            tileItems: tileViewItems.get()
          }
        };
      }
      return res;
    },
    {} as {
      kanbanState: {
        formKey: string;
        cardItems: KanbanState['cardItems'];
      };
      listViewState: {
        formKey: string;
        items: ILayoutItem[];
      };
      tileViewState: {
        formKey: string;
        tileItems: ILayoutItem[];
      };
    }
  );
};

const getEditorButtonsValues = (result: CommonResult[]): ILayoutItem<IButtonOptions>[] => {
  return result.reduce((res: Array<ILayoutItem<IButtonOptions>>, el) => {
    const {editorButtons} = el;
    if (editorButtons?.modified) {
      return [...res, ...editorButtons.get()];
    }
    return res;
  }, []);
};

const getElementsUserSettings = (result: CommonResult[]): ElementUserSettingsObj[] => {
  return result.reduce((data: ElementUserSettingsObj[], current) => {
    const {elementsUserSettings, formKey} = current;

    if (elementsUserSettings && elementsUserSettings?.modified) {
      return [
        ...data,
        {
          formKey,
          elementsUserSettings: elementsUserSettings.get()
        }
      ];
    }

    return [...data];
  }, []);
};

const nestedDataFromResult = (results: CommonResult[]) => {
  return results.reduce((data, {nestedFieldName, nestedData}) => {
    if (!(nestedFieldName && nestedData?.modified)) {
      return data;
    }
    return {
      ...data,
      [nestedFieldName]: nestedData.get()
    };
  }, {});
};

const copyCurrentFormResult = (
  currentForm: CurrentFormState,
  currentFormProps: Omit<CommonResult, 'formItems' | 'formGroups' | 'editableRowData' | 'formType' | 'focusedItem'>
) => {
  let options: Partial<CurrentFormState> = {};

  for (const key in currentFormProps) {
    // @ts-ignore
    if (currentForm?.hasOwnProperty(key) && !deepEqual(currentForm[key], currentFormProps[key])) {
      // @ts-ignore
      const value = currentFormProps[key];

      switch (true) {
        case isObject(value):
          // @ts-ignore
          options[key] = Object.assign({}, value);
          break;
        case isArray(value):
          // @ts-ignore
          options[key] = [...value];
          break;
        default:
          // @ts-ignore
          options[key] = value;
      }
    }
  }
  return options;
};

//Получение значений со всех форм на экране, что бы сделать диспатч события одновременно для всех форм, а не по отдельности
const getCurrentFormValues = ({result}: {result: CommonResult[]; processedForm: FormObject}) => {
  const nestedFormData = nestedDataFromResult(result);

  const state = store.getState();

  return result.map(({formItems, formGroups, editableRowData, formType, focusedItem, ...props}) => {
    let {formID, formKey, isAutoRefresh} = props;

    const currentForm: CurrentFormState = state.currentForm.get(formID);
    let options: Partial<CurrentFormState> = copyCurrentFormResult(currentForm, props);

    if (props.parentFormID == null && !isEmptyObject(nestedFormData)) {
      options.formData = {
        ...(options.formData || props.formData),
        ...nestedFormData
      };
    }

    if (editableRowData?.modified) {
      options.editableRowData = editableRowData.get();
    }

    const formTypeInst = new SysFormType(currentForm.formType);
    const isReadOnlyFormByAutoRefresh =
      isAutoRefresh && (formTypeInst.isAnyEdit() || formTypeInst.isFreeForm() || formTypeInst.isReportForm());

    options = {
      ...options,
      ...getCurrentFormState({formItems, formGroups}, props.readOnly, isReadOnlyFormByAutoRefresh)
    };

    return {formID, formKey, options};
  });
};

const updateFilterDocPanel = (results: CommonResult[]) => {
  const docPanels = results.map(({filterDockPanel}) => filterDockPanel).filter(isDefined) as FilterDocPanel[];
  return {
    filterPanelForms: docPanels.map(panel => {
      return {
        formID: panel.formID,
        options: {
          items: panel.filterItems.get()
        }
      };
    }),
    filterPanelFilters: getFilterValues(docPanels)
  };
};

const getSchedulers = (results: CommonResult[]) => {
  return results
    .filter(res => isDraft(res.schedulerResources))
    .map(res => {
      let out: {
        formKey: string;
        resources?: SchedulerResource[];
        startDayHour?: number;
        endDayHour?: number;
      } = {formKey: res.formKey};

      if (current(res.schedulerResources) !== original(res.schedulerResources)) {
        out.resources = finishDraft(res.schedulerResources);
      }
      out.startDayHour = res.schedulerStartDayHour;
      out.endDayHour = res.schedulerEndDayHour;

      return out;
    });
};

export const commonResult = ({
  result,
  processedForm
}: {
  result: CommonResult[] | boolean;
  processedForm: FormObject;
}) => {
  if (!isArray(result)) {
    return;
  }
  const {dispatch} = store;
  const res = result as CommonResult[];
  dispatch(updateCurrentScreen(getCurrentFormValues({result: res, processedForm})));
  dispatch(updateTables(getTableValues(res)));
  dispatch(updateSchedulers(getSchedulers(res)));
  dispatch(setFilters(getFilterValues(res)));
  dispatch(setEditorButtons(getEditorButtonsValues(res), processedForm.currentForm.formKey));
  dispatch(setElementsUserSettings(getElementsUserSettings(res)));

  const {kanbanState, listViewState, tileViewState} = getCardItems(res);
  listViewState && dispatch(setListViewItems(listViewState));
  tileViewState && dispatch(setTileViewItems(tileViewState));
  kanbanState && dispatch(setCardItems(kanbanState));

  const {formID, focusedItem} = itemFormFocus(res) || {};
  focusedItem && formID && dispatch(focusItem({actionFormId: formID, item: focusedItem}));

  const {filterPanelFilters, filterPanelForms} = updateFilterDocPanel(res);
  dispatch(updateCurrentScreen(filterPanelForms));
  dispatch(setFilters(filterPanelFilters));
};

function itemFormFocus(res: CommonResult[]): {
  formID: string;
  focusedItem: ILayoutItem<any> | undefined;
} | null {
  const mainResult = res.find(({focusedItem}) => !!focusedItem);
  if (!mainResult) return null;
  const {itemType, name} = mainResult.focusedItem!;
  return {
    formID: mainResult.formID,
    focusedItem: mainResult.formItems.get().find(item => item.itemType === itemType && item.name === name)
  };
}

//присваиваем значение readOnly для каждого item
const setItemsReadOnlyForm = (
  formItems: ILayoutItem<any>[],
  readOnly: boolean | undefined,
  isReadOnlyFormByAutoRefresh: boolean | undefined
) => {
  return formItems.map(item => {
    item.isReadOnlyForm = readOnly || isReadOnlyFormByAutoRefresh;
    return item;
  });
};
