import {DEFAULT_APPLICATION_NAME, system} from './objects';

const WAPI_USEPATH = process.env.REACT_APP_WAPI_USEPATH;
const USER_FOLDER_PATH = process.env.REACT_APP_USER_FOLDER_PATH;

export const wapiUrl = `${WAPI_USEPATH ? window.location.pathname : '/'}wapi`;
export const userScriptBaseUrl = `.${USER_FOLDER_PATH ? `/${USER_FOLDER_PATH}/scripts` : '/user-scripts'}`;
const userResourcesBaseUrl = `.${USER_FOLDER_PATH ? `/${USER_FOLDER_PATH}/resources` : '/user-resources'}`;
export const userFontsBaseUrl = `${userResourcesBaseUrl}/fonts/customFonts.css`;
export const userCSSUrl = `${userScriptBaseUrl}/styles.css`;

export const urlSignIn = wapiUrl + '/Sys_Login';
export const urlUserInfo = wapiUrl + '/Sys_Profile/List';
export const urlSignUp = wapiUrl + '/Sys_Login/RegisterUser';
export const urlActivation = wapiUrl + '/Sys_Login/ActivateUser';
export const urlCheckLogin = wapiUrl + '/Sys_Login/CheckLogin';
export const urlRestorePass = wapiUrl + '/Sys_Login/RestorePasswordRequest';
export const urlChangePass = wapiUrl + '/Sys_Login/ChangePassword';
export const urlSignOut = wapiUrl + '/logout';
export const urlUiParams = wapiUrl + '/Sys_UiParams/List';
export const urlHelpArticles = wapiUrl + '/Sys_HelpArticles/List';
export const urlAuthParams = wapiUrl + '/Sys_AuthParams/List';
export const urlLogo = wapiUrl + '/Sys_UiParams/List/1/Logo';

export const urlSysSubsystems = wapiUrl + '/Sys_Subsystems';

const applicationUrl = (appName: string) => `/app/${appName}`;
export const userScriptUrl = (appName = '') => {
  const loadFile = `loadUserScriptByName.js?ref=${Math.random()}`;

  let url = `${userScriptBaseUrl}/${loadFile}`;
  if (appName && appName.toLowerCase() !== DEFAULT_APPLICATION_NAME.toLowerCase()) {
    url = `${applicationUrl(appName)}/scripts/${loadFile}`;
  }
  if (url[0] === '.') url = url.substring(1);
  return url;
};

export const userResourceUrl = (appName = '') => {
  let url = userResourcesBaseUrl;
  if (url.startsWith('.')) url = url.substring(1);

  if (appName && appName.toLowerCase() !== DEFAULT_APPLICATION_NAME.toLowerCase()) {
    url = `${applicationUrl(appName)}/resources`;
  }
  return url;
};

export const getDiagramConfigUrl = (appName = '') => {
  return `${userResourceUrl(appName)}/${system.FILE_NAMES.DIAGRAM_CONFIG}.json?${Math.random()}`;
};
