import * as events from 'middlewares/userScript/events';
import {GroupsState} from 'middlewares/userScript/storeStates/groupsState';
import {ItemsState} from 'middlewares/userScript/storeStates/itemsState';
import {USER_EVENT_TYPE} from 'services/interfaces/global-interfaces';
import {ELayoutType} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {FormObject} from './utils/createFormObject';

const getEventStrategy = (eventType: USER_EVENT_TYPE, rest: Record<string, any>) => {
  switch (eventType) {
    case USER_EVENT_TYPE.ON_INIT_FORM:
      return events.onInit;
    case USER_EVENT_TYPE.ON_SHOW_FORM:
      return events.onShow;
    case USER_EVENT_TYPE.ON_SAVE_FORM:
      return events.onSave;
    case USER_EVENT_TYPE.ON_APPLY:
      return events.onApply;
    case USER_EVENT_TYPE.ON_CLOSE_FORM:
      return events.onClose;
    case USER_EVENT_TYPE.ON_SELECTION_CHANGED:
      return events.onSelectionChanged;
    case USER_EVENT_TYPE.ON_FOCUSED_CELL_CHANGED:
      return events.onFocusedCellChanged;
    case USER_EVENT_TYPE.ON_FIELD_VALUE_CHANGED: {
      switch (rest.componentInitiator) {
        case ELayoutType.TABLE:
          return events.onTableFieldValueChanged;
        case ELayoutType.KANBAN:
        case ELayoutType.ListView:
        case ELayoutType.TileList:
          return events.OnCardValueChanged;
        default:
          return events.onFieldValueChanged;
      }
    }
    case USER_EVENT_TYPE.ON_FILTER_VALUE_CHANGED:
      return events.onFilterValChanged;
    case USER_EVENT_TYPE.ON_INIT_LOOKUP_FILTER:
      return events.onInitLookupFilter;
    case USER_EVENT_TYPE.ON_INIT_LOOKUP_FIELD:
      return rest.componentInitiator === ELayoutType.TABLE ? events.onTableInitLookupField : events.onInitLookupField;
    case USER_EVENT_TYPE.ON_KEY_DOWN:
      return events.onKeyDown;
    case USER_EVENT_TYPE.ON_ROW_UPDATED:
    case USER_EVENT_TYPE.ON_ROW_INSERTED:
      return events.onRowModified;
    case USER_EVENT_TYPE.ON_ROW_DELETED:
      return events.onRowDeleted;
    case USER_EVENT_TYPE.ON_BUTTON_CLICK: {
      switch (rest.componentInitiator) {
        case ELayoutType.TABLE:
          return events.onTableBtnClick;
        case ELayoutType.KANBAN:
        case ELayoutType.ListView:
        case ELayoutType.TileList:
          return events.onCardButtonClick;
        default:
          return events.onButtonClick;
      }
    }
    case USER_EVENT_TYPE.ON_ROW_INSERTING:
      return events.onRowInserting;
    case USER_EVENT_TYPE.ON_ROW_EDITING:
      return events.onRowEditing;
    case USER_EVENT_TYPE.ON_BEFORE_ROW_EDITING: {
      if (rest.componentInitiator === ELayoutType.NAV_EDIT) return events.onNavEditBeforeRowEditing;
      return events.onBeforeRowEditing;
    }
    case USER_EVENT_TYPE.ON_BEFORE_ROW_INSERTING: {
      if (rest.componentInitiator === ELayoutType.NAV_EDIT) return events.onNavEditBeforeRowInserting;
      return events.onBeforeRowInserting;
    }
    case USER_EVENT_TYPE.ON_ROW_DELETING:
      return events.onRowDeleting;
    case USER_EVENT_TYPE.ON_FILTER_APPLY:
      return events.onApplyFilter;
    case USER_EVENT_TYPE.ON_CUSTOMIZE_ROW:
      return events.onCustomizeRow;
    case USER_EVENT_TYPE.ON_CUSTOMIZE_CELL:
      return events.onCustomizeCell;
    case USER_EVENT_TYPE.ON_CELL_CLICK:
    case USER_EVENT_TYPE.ON_CELL_DOUBLE_CLICK:
      return events.onCellClick;
    case USER_EVENT_TYPE.ON_DATA_LOADED:
      return events.onDataLoaded;
    case USER_EVENT_TYPE.ON_CUSTOMIZE_ROW_EDITOR:
      return events.onCustomizeRowEditor;
    case USER_EVENT_TYPE.ON_CARD_CLICK:
      return events.onCardClick;
    case USER_EVENT_TYPE.ON_CARD_DRAG_DROP_START:
      return events.onCardDragDropStart;
    case USER_EVENT_TYPE.ON_CARD_DRAG_DROP_FINISH:
      return events.onCardDragDropFinish;
    case USER_EVENT_TYPE.ON_DIAGRAM_ITEM_CLICK:
      return events.onDiagramItemClick;
    case USER_EVENT_TYPE.ON_DIAGRAM_ITEM_DOUBLE_CLICK:
      return events.onDiagramItemDoubleClick;
    case USER_EVENT_TYPE.ON_APPOINTMENT_ELEMENT_CLICK:
      return events.onAppointmentElementClick;
    case USER_EVENT_TYPE.ON_APPOINTMENT_ELEMENT_DOUBLE_CLICK:
      return events.onAppointmentElementDoubleClick;
    case USER_EVENT_TYPE.ON_DIAGRAM_SELECTION_CHANGED:
      return events.onDiagramSelectionChanged;
    case USER_EVENT_TYPE.ON_FIELD_VALIDATION:
      return events.onFieldValidation;
    case USER_EVENT_TYPE.ON_DECORATION_ELEMENT_CLICK:
      return events.onDecorationElementClick;
    case USER_EVENT_TYPE.ON_DECORATION_ELEMENT_DOUBLE_CLICK:
      return events.onDecorationElementDoubleClick;
    case USER_EVENT_TYPE.ON_SUMMARY_CALC:
      return events.onSummaryCalc;
    case USER_EVENT_TYPE.ON_GROUP_BUTTON_CLICK:
      return events.onGroupButtonClick;
    case USER_EVENT_TYPE.ON_ITEM_CLICK:
    case USER_EVENT_TYPE.ON_ITEM_DOUBLE_CLICK:
      return events.onItemClick;
    case USER_EVENT_TYPE.ON_ITEM_HOLD:
      return events.onItemHold;
    case USER_EVENT_TYPE.ON_ITEM_SWIPE:
      return events.onItemSwipe;
    case USER_EVENT_TYPE.ON_AUTOREFRESH:
      return events.onAutorefresh;
    case USER_EVENT_TYPE.ON_CUSTOMIZE_CELL_FORM: {
      if (rest.componentInitiator === ELayoutType.SCHEDULER) return events.onCustomizeCellScheduler;
      throw new Error('unknown user event');
    }
    default:
      throw new Error('unknown user event');
  }
};

type RunStrategy = {
  eventType: USER_EVENT_TYPE;
  payload: Record<string, any>;
  subForms: FormObject[];
  processedForm: FormObject;
  parentForm: FormObject | undefined;
  dispatch: any;
};

function runUserEvent({eventType, payload, subForms, processedForm, dispatch, parentForm}: RunStrategy) {
  const eventStrategy = getEventStrategy(eventType, payload);

  // @ts-ignore
  return eventStrategy({
    ...payload,
    //common
    items: payload.items ? new ItemsState(payload.items) : processedForm.formItems,
    groups: payload.groups ? new GroupsState(payload.groups, true) : processedForm.formGroups,
    eventType,
    subForms,
    processedForm,
    dispatch,
    parentForm
  });
}

export default runUserEvent;
