import {ClickEvent} from 'devextreme/ui/button';
import {
  ELayoutType,
  IButtonOptions,
  ILayoutItem,
  LayoutFieldItem
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {initTableState} from 'services/tables/actions';
import {getButtons, getDecorItems, getFilterItems, getFormFields, getGroups} from 'services/SecondaryMethods/formItems';
import {SysForm} from 'services/interfaces/sysObjects';
import store from 'store';
import {buildColumns} from 'components/TableCoreOld/buildColumns';
import {fields, system} from 'services/objects';
import {SysSubForm} from 'services/interfaces/sysObjects/SysSubForm';
import {
  BUTTON_POSITION,
  BUTTON_TYPE,
  FILTER_LAYOUT,
  FILTER_OPERATIONS_ID,
  USER_EVENT_TYPE
} from 'services/interfaces/global-interfaces';
import {FormField, SysFormWrapper} from 'utilsOld/systemObjects';
import {checkGroupsInLS, SavedGroupArrProps} from 'services/SecondaryMethods/formItems/getGroups';
import {AuthContextInterface} from '../../AuthProvider';
import {onKeyDown} from 'middlewares/userScript/events';
import {createEventPromise, userScriptActions} from 'services/currentForms/userScriptActions';
import {ITbButtonsPosition} from '../TableCoreOld/ColumnSettings/utils';
import {adjustFromFieldItems} from './formEdit/utils';
import {
  checkEditableEvents,
  componentInitiatorByFormType,
  createOnFieldValueChangeFunc,
  createOnFilterValueChangedFunc
} from './utils';
import createToolbarButtons from './createToolbarButtons';
import {formModifying} from 'services/currentForms/currentFormActions';
import FormEventQueue from 'utilsOld/formEventQueue/FormEventQueue';
import TableStateStorage from 'utilsOld/settingsStorage/TableStateStorage';
import {createValidationHandler} from '../TableCoreOld/columns/validation/createUserValidationRule';
import {ToggleTotalButton} from '../table/TableToolBarDirector';
import {fetchReportList} from '../../services/tables/operation';
import {createKanbanColumnsStorageKey} from 'utilsOld/storageKeys';

const {IN_GROUP, EDITOR_BUTTON, TILE_BUTTON, NONE} = BUTTON_POSITION;
const {CUSTOM, EDIT, VIEW} = BUTTON_TYPE;

const getInitButtons = ({
  instance,
  formKey,
  dispatch,
  componentInitiator
}: {
  instance: SysFormWrapper;
  formKey: string;
  dispatch: any;
  componentInitiator?: ELayoutType;
}) => {
  const buttonsClickAction = (_e: ClickEvent, name: string) => {
    dispatch(
      userScriptActions.onButtonClick({
        inheritMethod() {
          return Promise.resolve();
        },
        sender: undefined,
        usArguments: {},
        formID: instance.name,
        componentInitiator,
        name,
        formKey
      })
    );
  };
  return getButtons(instance.buttons, buttonsClickAction);
};

type InitFieldsParams = Omit<GetInitItemsParams, 'buttons'>;

export const getInitFields = ({instance, formKey, dispatch, eventQueue}: InitFieldsParams): LayoutFieldItem[] => {
  const onValueChange = createOnFieldValueChangeFunc({dispatch, formID: instance.name, formKey, eventQueue});

  const onKeyDownHandler = ({field, inputEvent}: {field?: FormField; inputEvent?: any}) => {
    if (checkEditableEvents(inputEvent)) {
      dispatch(formModifying({formID: instance.name, start: true}));
    }

    if (!field || !inputEvent) return;

    const hasOnKeyDownUSEvent = field.events?.find(usEvent => usEvent.EventTypeID === USER_EVENT_TYPE.ON_KEY_DOWN);
    if (!hasOnKeyDownUSEvent) {
      return;
    }
    try {
      const {event} = createEventPromise();
      onKeyDown({
        processedForm: {sysForm: instance.asRaw()},
        name: field.name,
        cancelableEvent: event,
        key: inputEvent.key,
        currentValue: inputEvent.target.value,
        eventType: USER_EVENT_TYPE.ON_KEY_DOWN
      });
    } catch (e) {
      inputEvent.preventDefault();
    }
  };

  let fieldItems = getFormFields({
    fields: instance.formFields,
    formKey,
    formDefaultTitleWidth: instance.fieldTitleDefaultWidth,
    onValueChanged: onValueChange,
    onInitFunction: userScriptActions.onInitLookupField,
    onKeyDown: onKeyDownHandler,
    onFocusOut: () => {
      dispatch(formModifying({formID: instance.name, start: false}));
    },
    hasScroll: instance.isFormBodyScrollable,
    validationCreator: createValidationHandler
  });

  return adjustFromFieldItems(fieldItems, instance);
};

export const getCustomConditionListInDependOnForm = (instance: SysFormWrapper) => {
  return (filterField: Record<string, any>) => {
    if (instance.type.isScheduler() && filterField.objectFieldIDName === instance.SCHEDULER_StartDateField) {
      return {
        isCustomConditionList: true,
        customConditionList: [FILTER_OPERATIONS_ID.equal]
      };
    }

    return null;
  };
};

export const getRequired = (instance: SysFormWrapper) => {
  return (filterField: Record<string, any>, required: boolean) => {
    if (instance.type.isScheduler() && filterField.objectFieldIDName === instance.SCHEDULER_StartDateField) {
      return true;
    }

    return required;
  };
};

const getInitFilters = (instance: SysFormWrapper, formKey: string, dispatch: any) => {
  const onFilterValueChanged = createOnFilterValueChangedFunc(dispatch, instance.name, formKey);

  return getFilterItems({
    formFilters: instance.filterFields,
    showInMain: true,
    formFields: instance.formFields,
    formKey,
    onInitFunction: userScriptActions.onInitLookupFilter,
    layout: FILTER_LAYOUT.LAYOUT,
    onValueChanged: onFilterValueChanged,
    conditionListInDependOnForm: getCustomConditionListInDependOnForm(instance),
    getRequired: getRequired(instance)
  });
};

interface GetInitItemsParams {
  buttons: ILayoutItem<IButtonOptions>[];
  instance: SysFormWrapper;
  formKey: string;
  dispatch: any;
  eventQueue: FormEventQueue;
}

const getInitItems = ({buttons, ...params}: GetInitItemsParams) => {
  const decorItems = getDecorItems(params.instance.decorationElements, params.instance.isFormBodyScrollable);

  const items: ILayoutItem<any>[] = [...buttons, ...decorItems];

  if (params.instance.type.isNavEdit()) {
    items.push(...[...getInitFilters(params.instance, params.formKey, params.dispatch), ...getInitFields(params)]);
    return items;
  }

  if (
    params.instance.type.isTableLike() ||
    params.instance.type.isKanban() ||
    params.instance.type.isScheduler() ||
    params.instance.type.isListView() ||
    params.instance.type.isTileList()
  ) {
    items.push(...getInitFilters(params.instance, params.formKey, params.dispatch));
  } else {
    items.push(...getInitFields(params));
  }

  return items;
};

interface InitCardItemsParams {
  sysForm: SysForm;
  formKey: string;
  dispatch: any;
  eventQueue: FormEventQueue;
}

export const initCardItems = ({sysForm, formKey, dispatch, eventQueue}: InitCardItemsParams) => {
  const instance = new SysFormWrapper(sysForm);
  const componentInitiator = componentInitiatorByFormType(sysForm.Type);

  const cardButtons = getInitButtons({
    instance,
    formKey,
    componentInitiator,
    dispatch
  }).filter(({options: {buttonType, position}}) => buttonType === CUSTOM && position === TILE_BUTTON);

  const decorItems = getDecorItems(instance.decorationElements, instance.isFormBodyScrollable);
  const fieldItems = getInitFields({
    instance,
    formKey,
    dispatch,
    eventQueue
  }).map(item => {
    const onValueChanged: ReturnType<typeof createOnFieldValueChangeFunc> = ({
      value,
      displayValue,
      dataField,
      relativeField,
      withUSEvent
    }) => {
      dispatch(
        userScriptActions.onValueChanged({
          formID: sysForm.Name,
          name: dataField,
          newValue: value,
          displayValue,
          formKey,
          relativeField,
          withUSEvent,
          componentInitiator
        })
      );
    };

    return {
      ...item,
      options: {
        ...item.options,
        onValueChanged
      }
    };
  });

  return [...cardButtons, ...decorItems, ...fieldItems];
};

interface InitItemsParams {
  sysForm: SysForm;
  formKey: string;
  dispatch: any;
  savedGroupArrProps: SavedGroupArrProps;
  eventQueue: FormEventQueue;
}

export function initItems({sysForm, formKey, dispatch, savedGroupArrProps, eventQueue}: InitItemsParams) {
  const sysFormInst = new SysFormWrapper(sysForm);
  const buttonItems = getInitButtons({
    instance: sysFormInst,
    formKey,
    dispatch
  });

  const buttons = buttonItems.filter(
    ({options: {buttonType, position}}) => (buttonType === CUSTOM && position === IN_GROUP) || position === NONE
  );

  const groups = getGroups(
    sysFormInst.isFormBodyScrollable,
    sysFormInst.groups,
    savedGroupArrProps,
    sysFormInst.fieldTitleDefaultWidth
  );

  const items = getInitItems({buttons, instance: sysFormInst, formKey, dispatch, eventQueue});

  const editorButtons = buttonItems.filter(({formFieldID, options: {buttonType, position}}) => {
    if (buttonType === EDIT || buttonType === VIEW) {
      const lookupField = items.find(item => item.id === formFieldID && item.options.lookupWithoutArray);

      return position === EDITOR_BUTTON && !!lookupField;
    }
    return position === EDITOR_BUTTON;
  });

  return {
    items,
    groups: checkGroupsInLS(groups, sysFormInst.name),
    editorButtons
  };
}

const loadReports = (sysForm: SysForm, formKey: string) => {
  const tableHasReportGroupButton = sysForm.Sys_FormButtons?.some(
    button => button.ButtonType === BUTTON_TYPE.REPORT_GROUP
  );

  if (tableHasReportGroupButton) {
    store.dispatch(fetchReportList(sysForm?.Name, formKey));
  }
};

export function initSubFormData({
  formKey,
  parentFormID,
  formIsModal,
  sysForm,
  subForm,
  regSetting,
  lsButtonsSettings,
  lsTotalRowKind,
  lsTotalRowVisible,
  auth,
  userId
}: {
  formKey: string;
  formID: string;
  parentFormID: string;
  sysForm: SysFormWrapper;
  regSetting: string;
  formIsModal: boolean;
  subForm: SysSubForm;
  auth: AuthContextInterface;
  lsButtonsSettings: ITbButtonsPosition[];
  lsTotalRowKind: ToggleTotalButton;
  lsTotalRowVisible: boolean;
  userId: number;
}) {
  try {
    let buttons = createToolbarButtons({
      sysForm,
      totalRowButton: lsTotalRowKind,
      formIsModal,
      lsButtons: lsButtonsSettings,
      totalRowVisible: lsTotalRowVisible
    });

    store.dispatch(
      initTableState({
        formKey,
        buttons,
        isShowTotalRow: lsTotalRowVisible,
        columns: buildColumns({
          isNestedTable: !!subForm?.NestedFieldID,
          maxLineCount: sysForm.rowMaxLineCount,
          regSetting,
          detailObjKeyFieldName: subForm?.[fields.DetailObjectFieldID_Name],
          sysForm: sysForm.asRaw(),
          parentFormID,
          lsName: sysForm.type.isKanban()
            ? createKanbanColumnsStorageKey(userId, formKey)
            : TableStateStorage.lsName(formKey),
          formKey
        })
      })
    );
    loadReports(sysForm.asRaw(), formKey);
  } catch (e: any) {
    if (e.name === system.USER_NOT_FOUND) {
      auth?.signOut();
    }
  }
}
